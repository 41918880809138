import "bootstrap/dist/css/bootstrap.css";
import { alpha } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ButtonGroup, Button } from "react-bootstrap";
import { withRouter, Route } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import {
  getImageUrl,
  getUserAvatarById,
  getUserNameById,
  getUserById,
  getDate,
  iAmOwner,
} from "../../Utils/Utils";
import app from "../../Main/base";

const HEADERS = [
  {
    prop: "actions",
    title: "Avatar",
    cell: (row) => {
      return <AvatarWithImage owner_id={row.ownerId} />;
    },
  },
  {
    prop: "ownerName",
    title: "Owner",
  },
  {
    prop: "year",
    title: "Year",
  },
  {
    prop: "model",
    title: "Model",
    isFilterable: true,
    // isSortable: true,
    // cell: (row) => {
    //   return getDate(row.date_start);
    // },
  },
  {
    prop: "basePlaneLocation",
    title: "Planes Base Location",
    isSortable: true,
  },
  {
    prop: "actions",
    title: "",
    cell: (row) => {
      return (
        <Route
          render={({ history }) => (
            <Button
              type="button"
              onClick={() => {
                //   history.push("/");
                history.push(`/edit-plane/${row.key}`);
              }}
            >
              Edit Plane!
            </Button>
          )}
        />
      );
    },
  },
  {
    prop: "actions",
    title: "",
    cell: (row) => {
      return (
        <Route
          render={({ history }) => (
            <Button
              type="button"
              variant="success"
              onClick={() => {
                //   history.push("/");
                history.push(`/messages`);
              }}
            >
              Message
            </Button>
          )}
        />
      );
    },
  },
];

// Then, use it in a component.
export default function PlanePrepTableComponent(props) {
  const [planesList, SetPlanesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    makePlanesList();
    setLoading(false);
  }, [loading]); //  Run once on load

  const makePlanesList = async () => {
    //let filteredList = props.trips.filter((trip) => trip.trip_phase === 1);
    let filteredList = props.myPlanes.filter(
      (plane) => plane.form_one_finished === true
    );

    let list = [];
    for (let plane of filteredList) {
      ///
      let _basePlaneLocation = plane.base_plane_location;
      let _ownerName = props.userName;
      let _year = plane.year;
      let _model = plane.model;
      let _key = plane.key;
      let _ownerId = plane.owner_id;

      const newItem = {
        basePlaneLocation: _basePlaneLocation,
        ownerName: _ownerName,
        year: _year,
        model: _model,
        key: _key,
        ownerId: _ownerId,
      };

      list.push(newItem);

      ///
    }

    SetPlanesList(list);
  };

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }
  return (
    <DatatableWrapper
      body={planesList}
      headers={HEADERS}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20],
        },
      }}
    >
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
        >
          <PaginationOptions />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Pagination />
        </Col>
      </Row>
      <Table>
        <TableHeader />
        <TableBody />
      </Table>
    </DatatableWrapper>
  );
}

function AvatarWithImage(props) {
  // Profile image of other person
  const [currentProfileImage, setCurrentProfileImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProfileImages();
    setLoading(false);
  }, [loading]); //  Run once on load

  const setProfileImages = () => {
    getUserAvatarById(props.owner_id).then(function (values) {
      console.log("value 1 ------- ", values);
      getImageUrl(app.storage(), values).then((result) => {
        console.log("item 1 ------- ", result);
        setCurrentProfileImage(result);
        // console.log("fuel link ------- ", currentProfileImage);
      });
    });
  };

  function iAmOwner() {
    let bool = props.owner_id === app.auth().currentUser.uid;
    // console.log("bool => ", bool);
    // setImOwner(bool);
    return bool;
  }

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return <Avatar aria-label="avatar" src={currentProfileImage}></Avatar>;
}
