import Banner from "./Banner.js";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, CardContent, TextField } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
  },
  pos: {
    marginBottom: 12,
  },
}));

const Video1 = () => {
  return (
    <iframe
      width="100%"
      height="600"
      src="https://www.youtube.com/embed/DsLw0gMc9dI"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  );
};

const Cards = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <CardContent container>
          <Typography color="textSecondary" align="center" variant="h1">
            <MonetizationOnIcon fontSize="inherit" color="primary" />
          </Typography>
          <Typography variant="h5" align="center" component="h2">
            Competitive Pricing
          </Typography>
          <Typography color="textSecondary" align="center">
            We compare our prices to the competition so that you always get the
            best deals.
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardContent>
          <Typography color="textSecondary" align="center" variant="h1">
            <DateRangeIcon fontSize="inherit" color="primary" />
          </Typography>
          <Typography variant="h5" align="center" component="h2">
            Open Scheduling Opportunities
          </Typography>
          <Typography color="textSecondary" align="center">
            No more reserving weeks in advance. Take flight quicker and easier.
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardContent>
          <Typography color="textSecondary" align="center" variant="h1">
            <FlightTakeoffIcon fontSize="inherit" color="primary" />
          </Typography>
          <Typography variant="h5" align="center" component="h2">
            Free Cancellations
          </Typography>
          <Typography color="textSecondary">
            Cancel up to 24hrs before your rental for no cost.
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
};

const Cards2 = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <CardContent container>
          <Typography
            variant="h5"
            align="center"
            component="h2"
            color="primary"
          >
            WHY RENT YOUR AIRCRAFT
          </Typography>
          <Typography color="textSecondary" align="center">
            Let your aircraft pay for itself. Sit back and relax while your
            aircraft makes money for you in an easy and convenient way.
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardContent>
          <Typography
            variant="h5"
            align="center"
            component="h2"
            color="primary"
          >
            IT IS ALL UP TO YOU
          </Typography>
          <Typography color="textSecondary" align="center">
            You are in complete control. We will provide estimates of rental
            prices based on market size, value, and time of the year but
            ultimately its up to you!
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardContent>
          <Typography
            variant="h5"
            align="center"
            component="h2"
            color="primary"
          >
            WE HAVE YOUR BACK
          </Typography>
          <Typography color="textSecondary" align="center">
            Skykandy offers 24/7 support, tools, checklist, everything you need
            to successfully make money with no problems at all.
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" color="primary">
          DON’T WORRY YOU’RE COVERED
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CardContent>
          <Typography color="textSecondary" align="center">
            No matter what kind of aircraft you have SkyKandy makes it simple
            and secure to rent your aircraft. You can even choose for the pilot
            to complete our 30 minute checkout with you before the rental for
            more assurance.
          </Typography>
        </CardContent>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CardContent>
          <Typography color="textSecondary" align="center">
            To keep your aircraft safe it is mandatory for pilots to have
            renters insurance and complete an annual flight review. The amount
            of aircraft damage coverage will be based off the type of aircraft
            being rented.
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
};

const Home = (props) => {
  const classes = useStyles();
  const { firebase } = props;
  return (
    <div className="FrontPage">
      <Banner imageUrl="/Skykandy_Banner.png">
        <Container>
          <Box m={15}>
            <Grid container>
              <grid item xs={6}>
                <Typography variant="h2">
                  Where options mean everything!
                </Typography>
              </grid>
              <Grid item xs={12}>
                <TextField color="white"></TextField>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Banner>
      <Container className={classes.row}>
        <Box m={10}>
          <Typography variant="h4" align="center" color="primary">
            An Aircraft Marketplace for the people by the people
          </Typography>
        </Box>
        <Box marginBottom={19}>
          <Cards />
        </Box>
      </Container>
      <Banner imageUrl="/frontPageAirPlane.0cf97369.jpg"></Banner>
      <Container>
        <Box m={10}>
          <Typography variant="h4" align="center" color="primary">
            Let your aircraft pay for itself
          </Typography>
        </Box>
        <Box margin={10}>
          <Cards2 />
        </Box>
      </Container>
      <Container>
        <Box m={3}>
          <Video1 className={classes.row} />
        </Box>
      </Container>
    </div>
  );
};

export default Home;
