export const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const validPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const lettersOnly = /^[a-zA-Z\s]+$/;
export const numbersOnly = /^[0-9]+$/;

export const sanitizePhone = (phone, style = "parens") => {
  const nums = phone.replace(/\D/g, "");

  const firstIsOne = nums.substring(0, 1) === "1";

  const parens = style === "parens";

  const withFirstIsOne = parens
    ? `(${nums.substring(1, 3)}`
    : nums.substring(1, 3);

  const withoutFirstIsOne = parens ? `(${nums}` : nums;

  const areaCode = firstIsOne
    ? parens
      ? `(${nums.substring(1, 4)}) `
      : `${nums.substring(1, 4)}.`
    : parens
    ? `(${nums.substring(0, 3)}) `
    : `${nums.substring(0, 3)}.`;

  const firstThree = firstIsOne ? nums.substring(4, 7) : nums.substring(3, 6);
  const lastFour = firstIsOne ? nums.substring(7, 11) : nums.substring(6, 10);

  let sanitized = "";

  if (nums && nums.length <= 3) {
    sanitized = firstIsOne ? withFirstIsOne : withoutFirstIsOne;
  } else if (nums && nums.length <= 6) {
    sanitized = areaCode + firstThree;
  } else if (nums && nums.length >= 6) {
    sanitized = areaCode + firstThree + (parens ? "-" : ".") + lastFour;
  }

  return sanitized;
};
