// import React from 'react';
import React, { useState, useEffect } from "react";
import app from "../Main/base";
import PropTypes from "prop-types";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { Container } from "@material-ui/core";

// import { getTrips } from "../hooks/trip";
//import { getTrip, getTrips } from '../hooks/trip';

import TripContext from "../contexts/TripContext";

// import { Trip } from "../components/Models/Trip";

import ApprovedTableComponent from "./TripPhases/ApprovedTableComponent";
import RequestTableComponent from "./TripPhases/RequestTableComponent";
import PendingTableComponent from "./TripPhases/PendingTableComponent";
import PastTableComponent from "./TripPhases/PastTableComponent";
// import firebase from "firebase/app"; // <---- This must be first
// import firebaseConfig from "../firebaseConfig";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

//TODO Trip checklist
//**Check-in
//**logbook review
//**pre-flight
//**30-minute checkout
//**flight hobs time

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TripsTabs(props) {
  // -------------------------------
  const [state, setState] = useState({ value: 0 });

  function update() {
    setState({ value: state.value + 1 });
  }
  // const ParentComponent = () =>
  const [reRender, setReRender] = useState(false);

  const handleReRender = () => {
    setReRender(!reRender); // state change will re-render parent
  };

  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const { tabs } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uid = app.auth().currentUser.uid;

  // Trips
  const [loading, setLoading] = useState(true);
  const [myTrips, setMyTrips] = useState([]);

  useEffect(() => {
    getTripsAsOwner();
    return () => {
      // setState(0);
      // setReRender(false);
      // setValue(0);
      // setLoading(true);
      // setMyTrips([]);
    };
  }, [loading]); //  Run once on load

  const getTripsAsOwner = () => {
    setMyTrips([]);
    const getTripsFromFirebase = [];
    const uid = app.auth().currentUser.uid;

    const db = app.firestore();
    const subscriber = db
      .collection("Trips")
      .where("owner_id", "==", uid)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getTripsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        //setMyTrips(getTripsFromFirebase);
        //setLoading(false);
      });

    db.collection("Trips")
      .where("pilot_id", "==", uid)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getTripsFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setMyTrips(getTripsFromFirebase);
        // updateTripPhase();
        setLoading(false);
      });

    return () => subscriber();
  };

  const updateTripPhase = () => {
    myTrips.map((trip) => {
      if (trip.trip_phase_owner == trip.trip_phase_pilot) {
        app
          .firestore()
          .collection("Trips")
          .doc(trip.key)
          .set(
            {
              ...trip,
              trip_phase: trip.trip_phase_owner,
            },
            { merge: true }
          )
          .then(() => {
            // console.log("Document successfully updated!");
            props.update();
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      } else {
        let lowest = -1;
        // if(trip.trip_phase_owner < trip.trip_phase_pilot ){
        //   lowest = trip.trip_phase_owner
        // }else{
        //   lowest = trip.trip_phase_pilot
        // }
      }
    });
  };

  // get all the list of trips from Trips with these ids
  // then filter
  // send this final trip
  // .filter(trip => trip.trip_pahse == 0);
  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    <Container style={{ height: "100vh" }}>
      <TripContext.Provider value={myTrips}>
        <TripContext.Consumer>
          {(myTrips) => {
            // console.log('  myTrips  => ', myTrips);
            return (
              <div>
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Request" {...a11yProps(0)} />
                      <Tab label="Approved" {...a11yProps(1)} />
                      <Tab label="Pending" {...a11yProps(2)} />
                      <Tab label="Past" {...a11yProps(3)} />
                    </Tabs>
                  </AppBar>
                  {/*  */}
                  <TabPanel value={value} index={0}>
                    <Typography component={"span"}>Request</Typography>
                    <div className="">
                      <RequestTableComponent trips={myTrips} />
                    </div>
                  </TabPanel>
                  {/*  */}
                  <TabPanel value={value} index={1}>
                    <Typography>Approved</Typography>
                    {/* <ApprovedTrips /> */}
                    <div className="">
                      <ApprovedTableComponent trips={myTrips} />
                    </div>
                  </TabPanel>
                  {/*  */}
                  <TabPanel value={value} index={2}>
                    <Typography>Pending</Typography>
                    <div className="">
                      <PendingTableComponent trips={myTrips} />
                    </div>
                  </TabPanel>
                  {/*  */}
                  <TabPanel value={value} index={3}>
                    <Typography>Past</Typography>
                    <div className="">
                      <PastTableComponent trips={myTrips} />
                    </div>
                  </TabPanel>
                </div>
                {/* <p>
                  {" "}
                  user_id: {uid}
                  owner_id: {myTrips.trips}
                  {JSON.stringify(props.user.id)}
                </p> */}
                <br />
                {/* <p>{JSON.stringify(props.user)}</p> */}
                <br />
                <br />
                {/* <p> Trip {JSON.stringify(props.trip)}</p> */}
                {/* <br />
                <p> Trips {JSON.stringify(myTrips.trips)}</p>
                <br />
                <p> Trips phase ? {JSON.stringify(myTrips.trips)}</p>
                <br />
                <h2>Owner</h2>
                <ul>
                  <li>As an owner i can push stuff to the approved colmun </li>
                  <li>if userid == owner id: make visable </li>
                </ul>
                <br />
                <h2>Pilot</h2>
                <ul>
                  <li>Can view its pending but cant affect it </li>
                  <li>if userid == pilot id: make visable </li>
                </ul>
                <br /> */}
              </div>
            );
          }}
        </TripContext.Consumer>
      </TripContext.Provider>
    </Container>
  );
}
