import React, { useEffect, useState, Component } from "react";
import { useForm } from "react-hook-form";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarComponent.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import StaticTime from "../../Models/StaticTime";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";

//import TimePicker from "react-time-picker";

import Spinner from "react-bootstrap/Spinner";

import app from "../../Main/base";
import RecurringTime from "../../Models/RecurringTime";
import {
  getDoc,
  getImageUrl,
  updateDoc,
  updateDoc2,
  toDateTime,
  reload,
} from "../Utils";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

function WeekDayComponent({
  onHandleAddDay,
  onHandleUpdateDay,
  onHandleDeleteDay,
  day_object,
}) {
  // ================= Default Date =================

  const [selectedDate, setSelectedDate] = useState(day_object.start);
  const handleDateChange = (date) => {
    day_object.start = date;

    setSelectedDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = useState(day_object.end);
  const handleEndDateChange = (date) => {
    day_object.end = date;
    setSelectedEndDate(date);
  };
  const [myString, setMyString] = useState("Google");
  //  ================= ======== React Hook Forms Start ========= =================
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    watch: watchAdd,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: day_object,
  });
  //  ================= ======== React Hook Forms End ========= =================
  const [flat_rate, rate_per_hour] = watchAdd(["flat_rate", "rate_per_hour"]);

  const onSubmit = async (data) => {
    //alert("Oh boy, data =>" + myString + " - " + JSON.stringify(data));
    onHandleAddDay(JSON.stringify(data));
    console.log("Oh boy, data =>", myString);
  };

  const handleAddEvent = async (data) => {
    day_object.flat_rate = data.flat_rate;
    day_object.rate_per_hour = data.rate_per_hour;
    onHandleAddDay(day_object);
    console.log("Oh boy, data =>", myString);
  };

  const handleResetEvent = async (data) => {
    reset();
  };

  const handleUpdateEvent = async (data) => {
    onHandleUpdateDay(day_object);
  };

  const handleDeleteEvent = async (data) => {
    onHandleDeleteDay(day_object);
  };

  // color:""lightblue"",
  // end:October 16, 2022 at 12:00:00 AM UTC-4
  // flat_rate:0
  // pilot_id:""
  // rate_per_hour:0
  // start: October 16, 2022 at 12:00:00 AM UTC-4
  // title:"SUN"
  // active : false
  return (
    <div>
      <Card style={{ width: "18rem" }}>
        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
        <Card.Body>
          <Card.Title>{day_object.title}</Card.Title>
          <Form onSubmit={handleSubmit(onSubmit)} className="px-3">
            <InputGroup className="mb-3 reg-row-container">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Start</Form.Label>

                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      className="mb-3"
                    >
                      <TimePicker
                        margin="normal"
                        id="start-date"
                        //format="E, dd MMM yyy - hh:mm a"
                        // label="Last Bi-Annual flight review"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        //value={selectedDate}
                        value={day_object.start}
                        onChange={handleDateChange}
                        // KeyboardButtonProps={{
                        //   "aria-label": "change date",
                        // }}
                        style={{ width: "100%" }}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>End</Form.Label>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      className="mb-3"
                    >
                      <TimePicker
                        margin="normal"
                        id="start-date"
                        //format="E, dd MMM yyy - hh:mm a"
                        // label="Last Bi-Annual flight review"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        //value={selectedEndDate}
                        value={day_object.end}
                        onChange={handleEndDateChange}
                        // KeyboardButtonProps={{
                        //   "aria-label": "change date",
                        // }}
                        style={{ width: "100%" }}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                </Col>
              </Row>
            </InputGroup>
            <InputGroup className="mb-3">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Price ph</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="number"
                      isInvalid={!!errors.rate_per_hour}
                      {...register("rate_per_hour", {
                        required: "Price per hour is required",
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.rate_per_hour?.message}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Flate Rate</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="number"
                      isInvalid={!!errors.flat_rate}
                      {...register("flat_rate", {
                        required: "Flate Rate is required",
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.flat_rate?.message}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </InputGroup>
            <InputGroup className="mb-3">
              <ButtonGroup
                size="lg"
                className="mb-3"
                style={{ left: "auto", width: "100%" }}
              >
                <Button
                  type="submit"
                  onClick={handleSubmit(handleAddEvent)}
                  size="lg"
                  className="mt-3 w-100"
                >
                  Add
                  {/* Recurring Time */}
                </Button>

                <Button
                  type="submit"
                  //disabled={isSubmitting}
                  //onClick={handleSubmit(handleDeleteEvent)}
                  onClick={handleSubmit(handleResetEvent)}
                  size="lg"
                  className="mt-3 w-100"
                >
                  Reset
                </Button>
              </ButtonGroup>
              <ButtonGroup
                size="lg"
                className="mb-3"
                style={{ left: "auto", width: "100%" }}
              >
                <Button
                  type="submit"
                  //disabled={isSubmitting}
                  onClick={handleSubmit(handleUpdateEvent)}
                  size="lg"
                  className="mt-3 w-100"
                >
                  Update
                </Button>
                <Button
                  type="submit"
                  //disabled={isSubmitting}
                  onClick={handleSubmit(handleDeleteEvent)}
                  size="lg"
                  className="mt-3 w-100"
                >
                  Delete
                </Button>
              </ButtonGroup>
            </InputGroup>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default WeekDayComponent;
