import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "geofire-common";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBvpoFp4hTcAIpjlcBqIGb8UMOjDzQ9_ig",
  authDomain: "skykandy-genesis.firebaseapp.com",
  projectId: "skykandy-genesis",
  storageBucket: "skykandy-genesis.appspot.com",
  messagingSenderId: "5656173200",
  appId: "1:5656173200:web:3556ca027389bc4316d615",
  measurementId: "G-CPTGSDQTYK",
});

export default app;
