import React, { useState } from "react";
import app from "../../Main/base";
import { useEffect } from "react";
import { useParams } from "react-router";
//------
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ListItem, ListItemText, List, Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Container } from "@material-ui/core";
import Form from "react-bootstrap/Form";

import EditPlaneForm from "./Forms/EditPlaneForm";
import Availibility from "./Forms/Availibility";
import InspectionInfo from "./Forms/InspectionInfo";
import CarouselComponent from "./Forms/CarouselComponent";
import { set } from "date-fns";

import {
  getImageUrl,
  putStorageItem,
  getUserNameById,
} from "../../Utils/Utils";

import Previews from "../../Utils/Dropzones/Previews";
import { usePlaneImages } from "../../../hooks/usePlaneImages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "33.33%",
  },
  thirdHeading: {
    fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
    flexBasis: "33.33%",
  },
  receivedRequest: {
    backgroundColor: "yellow",
  },
  sentRequest: {
    backgroundColor: "red",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
}));

const EditPlane = (props) => {
  const defaultImage = "";
  const { id } = useParams();
  const { displayImages, inspectionImages } = usePlaneImages(id); // <==== look hear on how to get image list
  const classes = useStyles();

  const [ownerChecklist_Id, setOwnerChecklist_Id] = useState("");
  const [pilotChecklist_Id, setPilotChecklist_Id] = useState("");

  // Trip
  const [loading, setLoading] = useState(true);
  const [thisTrip, setThisTrip] = useState([]);

  //sub Collection of Trip, CheckList
  const [thisTripContract, setThisTripContract] = useState({});

  // Setting images
  const [image, setImage] = useState(null);
  const [state, setState] = useState(defaultImage);
  const [currentFuel_One, setCurrentFuel_One] = useState(null);

  const [flight_One, setFlight_One] = useState(null);
  const [stateTwo, setStateTwo] = useState(defaultImage);
  const [currentFlight_One, setCurrentFlight_One] = useState(null);

  const [flight_Two, setFlight_Two] = useState(null);
  const [stateThree, setStateThree] = useState(defaultImage);
  const [currentFlight_Two, setCurrentFlight_Two] = useState(null);

  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setState(reader.result);
        setImage(e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleImageChangeFlightOne = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setStateTwo(reader.result);
        setFlight_One(e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleImageChangeFlightTwo = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setStateThree(reader.result);
        setFlight_Two(e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    // getTrip();
    // getOwnerUserName(thisTrip.owner_id);
    // getPilotUsername(thisTrip.pilot_id);

    // setDBimages();

    setLoading(false);
  }, [loading]); //  Run once on load

  const getTrip = () => {
    setThisTrip({});

    const db = app.firestore();
    const subscriber = db
      .collection(`Trips`)
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          setThisTrip(doc.data());
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
          setThisTrip(null);
        }
        // setLoading(false);
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
    return () => subscriber();
  };

  const setDBimages = () => {
    let item = getImageUrl(thisTripContract.fuel_One);
    let itemFlight_One = getImageUrl(thisTripContract.flight_One);
    let itemFlight_Two = getImageUrl(thisTripContract.flight_Two);

    item.then((result) => {
      // console.log("item 1 ------- ", result);
      setCurrentFuel_One(result.toString());
      // console.log("fuel link ------- ", currentFuel_One);
    });

    itemFlight_One.then((result) => {
      // console.log("item 1 ------- ", result);
      setCurrentFlight_One(result.toString());
      // console.log("flight one link ------- ", currentFlight_One);
    });

    itemFlight_Two.then((result) => {
      // console.log("item 1 ------- ", result);
      setCurrentFlight_Two(result.toString());
      // console.log("flight two link ------- ", currentFlight_Two);
    });
  };

  const handleSubmitOwner = async () => {
    let ownerImagePaths = [
      { path: thisTripContract.fuel_One, image: image },
      { path: thisTripContract.flight_One, image: flight_One },
      { path: thisTripContract.flight_Two, image: flight_Two },
    ];
    Promise.all(
      // Array of "Promises"
      ownerImagePaths.map((item) => putStorageItem(item))
    )
      .then((url) => {
        // console.log(`All success`);
      })
      .catch((error) => {
        // console.log(`Some failed: `, error.message);
      });

    // console.log("UPDATING THIS ================> ", thisTripContract);
    app
      .firestore()
      .collection(`Trips/${id}/checklist_owner`)
      .doc(ownerChecklist_Id)
      .set(
        {
          ...thisTripContract,
          //   checkInState_Out: true,
        },
        { merge: true }
      )
      .then(() => {
        // console.log("Document successfully updated!");
        props.update();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const handleSubmitPilot = async () => {
    let pilotImagePaths = [
      { path: thisTripContract.fuel_One, image: image },
      { path: thisTripContract.flight_One, image: flight_One },
      { path: thisTripContract.flight_Two, image: flight_Two },
    ];
    Promise.all(
      // Array of "Promises"
      pilotImagePaths.map((item) => putStorageItem(item))
    )
      .then((url) => {
        // console.log(`All success`);
      })
      .catch((error) => {
        // console.log(`Some failed: `, error.message);
      });

    // console.log("UPDATING THIS ================> ", thisTripContract);
    app
      .firestore()
      .collection(`Trips/${id}/checklist_pilot`)
      .doc(pilotChecklist_Id)
      .set(
        {
          ...thisTripContract,
          //   checkInState_Out: true,
        },
        { merge: true }
      )
      .then(() => {
        // console.log("Document successfully updated!");
        props.update();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const [ownerName, setOwnerName] = useState("");
  const getOwnerUserName = (userId) => {
    app
      .firestore()
      .collection(`chatUsers`)
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          setOwnerName(doc.data().firstName);
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
  };

  const [pilotName, setPilotName] = useState("");
  const getPilotUsername = async (userId) => {
    app
      .firestore()
      .collection(`chatUsers`)
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          setPilotName(doc.data().firstName);
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
  };

  function iAmOwner() {
    return thisTrip.owner_id === app.auth().currentUser.uid;
  }
  if (loading) {
    return <h1>loading firebase data...</h1>;
  }
  return (
    <Container>
      <div className={classes.root}>
        <p> plane id: {id}</p>
        {/* <Previews /> */}
        <CarouselComponent images={displayImages} plane_id={id} />
        <EditPlaneForm plane_id={id} />
        <Availibility plane_id={id} />
        <InspectionInfo images={inspectionImages} plane_id={id} />
      </div>
    </Container>
  );
};

export default EditPlane;
