import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Brand = () => {
  return (
    <Navbar.Brand>
      <Link to="/">
        <img
          src="/sky-kandy-logo.png"
          alt="sky kandy"
          style={{ maxWidth: "10rem" }}
        ></img>
      </Link>
    </Navbar.Brand>
  );
};

export default Brand;
