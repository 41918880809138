import { useState, useEffect } from "react";

import app from "../../../Main/base";
import { PlaneForm } from "./PlaneForm";

export const EditPlaneForm = ({ plane_id }) => {
  const [loading, setLoading] = useState(true);
  const [existingPlane, setExistingPlane] = useState();

  useEffect(() => {
    getPlane();
  }, [loading]); //  Run once on load

  const getPlane = async () => {
    const db = app.firestore();
    const subscriber = db
      .collection("plane")
      .doc(plane_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Set Plane to this Document data: ", doc.data());
          setExistingPlane(doc.data());
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });

    return () => subscriber();
  };

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    <div>
      <p> plane id: {plane_id}</p>
      <PlaneForm existingPlane={existingPlane} planeId={plane_id} />
    </div>
  );
};

export default EditPlaneForm;
