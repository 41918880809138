import React, { useEffect, useState, Component } from "react";
import { useForm } from "react-hook-form";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarComponent.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import {
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";

//import TimePicker from "react-time-picker";

import Spinner from "react-bootstrap/Spinner";

import app from "../../Main/base";
import RecurringTime from "../../Models/RecurringTime";
import StaticTime from "../../Models/StaticTime";
import {
  getDoc,
  getImageUrl,
  updateDoc,
  updateDoc2,
  toDateTime,
  reload,
} from "../Utils";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

import ToggleDays from "../Calendars/ToggleDays";

function AddRecEventComponent({ onHandleAddDay, handleAddRecurringEvent }) {
  // ================= ======== Add new Dates ========= =================
  const [dateError, setDateError] = useState("");
  const [selectedNewStartDate, setSelectedNewStartDate] = useState(null);
  const handleNewStartDateChange = (date) => {
    if (!date) {
      setDateError("Date can not be empty");
      return null;
    }

    if (selectedNewEndDate != null) {
      date.getTime() > selectedNewEndDate.getTime()
        ? setDateError("Dates Out Of Order!")
        : setDateError("");
    }
    setSelectedNewStartDate(date);
  };
  //
  const [selectedNewEndDate, setSelectedNewEndDate] = useState(null);
  const handleNewEndDateChange = (date) => {
    if (!date) {
      setDateError("Date can not be empty");
      return null;
    }
    if (selectedNewStartDate != null) {
      date.getTime() < selectedNewStartDate.getTime()
        ? setDateError("Dates Out Of Order!")
        : setDateError("");
    }
    setSelectedNewEndDate(date);
  };
  //  ================= ======== Add new Dates End ========= =================

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isDirty },
    watch: watchAdd,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: new StaticTime(),
  });

  const [flat_rate_add, rate_per_hour_add, title_add] = watchAdd([
    "flat_rate",
    "rate_per_hour",
    "title",
  ]);

  const handleResetEvent = async (data) => {
    setSelectedNewStartDate(null);
    setSelectedNewEndDate(null);
    reset();
  };

  //   const [daysError, setDaysError] = useState("");
  //   const [days, setDays] = useState(null);
  //   const handleDaysChange = async (data) => {
  //     // alert(data);
  //     console.log("handleDaysChange " + data);
  //     setDays(data);
  //     if (data.length <= 0) {
  //       setDaysError("At least one day must be selected");
  //     } else if (daysError != "") {
  //       setDaysError("");
  //     }
  //   };

  const onSubmit = async (data) => {
    if (isDirty) {
      if (
        selectedNewStartDate != null &&
        selectedNewEndDate != null &&
        dateError == ""
        //days != null &&
        // && daysError == ""
      ) {
        const pid = app.firestore().collection("pid").doc().id;
        // console.log("pid => ", pid);
        let recuringTime = {
          active: false,
          time_id: pid,
          end: selectedNewEndDate,
          flat_rate: data.flat_rate,
          pilot_id: "",
          rate_per_hour: data.rate_per_hour,
          start: selectedNewStartDate,
          //title: data.title,
          color: "lightblue",
        };
        // onHandleAddDay(recuringTime, days);
        handleAddRecurringEvent(recuringTime, title_add);
        reset();
        setSelectedNewEndDate(null);
        setSelectedNewStartDate(null);
        //setDays(null);
      } else if (
        (selectedNewStartDate == null || selectedNewEndDate == null) &&
        dateError != ""
      ) {
        setDateError("Date can not be empty");
      }
      //    else if (days == null && daysError != "") {
      //     setDaysError("At least one day must be selected");
      //   }
    }
    // alert("Oh boy, data =>" + myString + " - " + JSON.stringify(data));
    // onHandleWordChange(JSON.stringify(data));
    // console.log("Oh boy, data =>", myString);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="px-3 pb-5">
        <Form.Label>Add A New Event</Form.Label>
        <div>
          <InputGroup className="mb-3 reg-row-container">
            <Form.Group>
              <Form.Label>Day of Week</Form.Label>
              <Form.Select
                aria-label="title"
                isInvalid={!!errors.title}
                {...register("title", {
                  required: "Day is required",
                })}
              >
                <option value={""}></option>
                <option value={"SUN"}>SUN</option>
                <option value={"MON"}>MON</option>
                <option value={"TUE"}>TUE</option>
                <option value={"WED"}>WED</option>
                <option value={"THU"}>THU</option>
                <option value={"FRI"}>FRI</option>
                <option value={"SAT"}>SAT</option>
              </Form.Select>
              <Form.Text className="text-danger">
                {errors.title?.message}
              </Form.Text>
              {/* <ToggleDays handleDaysChange={handleDaysChange} />

              {daysError && (
                <FormHelperText className="carousel-error error-text">
                  {daysError}
                </FormHelperText>
              )} */}
            </Form.Group>
          </InputGroup>
          <br />
          <InputGroup className="mb-3 reg-row-container">
            <Box style={{ width: "50%", marginRight: "1px" }}>
              <Typography>
                Input your Start Date and time here please
              </Typography>
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
              <TimePicker
                margin="normal"
                id="start-date"
                //format="E, dd MMM yyy - hh:mm a"
                // label="Last Bi-Annual flight review"
                // value={selectedDate}
                // onChange={handleDateChange}
                value={selectedNewStartDate}
                onChange={handleNewStartDateChange}
                // KeyboardButtonProps={{
                //   "aria-label": "change date",
                // }}
                style={{ width: "50%" }}
              />
            </MuiPickersUtilsProvider>
          </InputGroup>
          <InputGroup className="mb-3 reg-row-container">
            <Box style={{ width: "50%", marginRight: "1px" }}>
              <Typography>Input your End Date and time here please</Typography>
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
              <TimePicker
                margin="normal"
                id="start-date"
                //format="E, dd MMM yyy - hh:mm a"
                // label="Last Bi-Annual flight review"
                // value={selectedDate}
                // onChange={handleDateChange}
                value={selectedNewEndDate}
                onChange={handleNewEndDateChange}
                // KeyboardButtonProps={{
                //   "aria-label": "change date",
                // }}
                style={{ width: "50%" }}
              />
            </MuiPickersUtilsProvider>
          </InputGroup>

          {dateError && (
            <FormHelperText className="carousel-error error-text">
              {dateError}
            </FormHelperText>
          )}
          <InputGroup className="mb-3">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Price Per Hour</Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="number"
                    isInvalid={!!errors.rate_per_hour}
                    {...register("rate_per_hour", {
                      required: "Price per hour is required",
                    })}
                  />
                  <Form.Text className="text-danger">
                    {errors.rate_per_hour?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Flate Rate</Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="number"
                    isInvalid={!!errors.flat_rate}
                    {...register("flat_rate", {
                      required: "Flate Rate is required",
                    })}
                  />
                  <Form.Text className="text-danger">
                    {errors.flat_rate?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </InputGroup>
          <br />
          <InputGroup className="mb-3">
            <ButtonGroup
              size="lg"
              className="mb-3"
              style={{ left: "auto", width: "100%" }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                //onClick={handleAddEvent}
                size="lg"
                className="mt-3 w-100 reg-mr-5"
              >
                Add Event
              </Button>
              <Button
                type="submit"
                onClick={handleResetEvent}
                size="lg"
                className="mt-3 w-100 reg-mr-5"
              >
                Reset
              </Button>
            </ButtonGroup>
          </InputGroup>
        </div>
      </Form>
    </div>
  );
}

export default AddRecEventComponent;
