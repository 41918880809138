import { setDayOfYear } from "date-fns";
import { useEffect, useState } from "react";
import app from "../components/Main/base";
import { getDoc, getImageUrl } from "../components/Utils/Utils";

export const useOwnerImages = (collectionPath, Id) => {
  //   const [displayImages, setDisplayImages] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePath, setImagepath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const db = app.firestore();
    const storage = app.storage();

    const getCollection = (collectionPath, Id) =>
      getDoc(db, collectionPath, Id);

    const getOwnerImages = async (imagesList) => {
      try {
        let pathList = [];
        const images = imagesList.map(async ({ image_path, name }) => {
          pathList.push(image_path);
          const url = await getImageUrl(storage, image_path);
          return { url, name };
        });
        setImagepath(pathList);
        return Promise.all(images);
      } catch (error) {
        throw error;
      }
    };

    try {
      const loadImages = async () => {
        const { checklist_owner_images } = await getCollection(
          collectionPath,
          Id
        );
        const [images] = await Promise.all([
          getOwnerImages(checklist_owner_images),
        ]);

        setImages(images);
      };

      loadImages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [app, collectionPath, Id]);

  return { images, imagePath, isLoading };
};
