export default class StaticTime {
  // constructor() {
  //   this.start = new Date(1970, 0, 1);
  //   this.end = new Date(1970, 0, 1);
  //   this.flatRate = 500;
  //   this.rate_per_hour= 40;
  //   this.pilot_id = "";
  //   this.title = "static time";
  // }

  constructor(
    time_id = "",
    start = null,
    end = null,
    flat_rate = null,
    rate_per_hour = null,
    pilot_id = "",
    title = "",
    color = "lightgreen"
  ) {
    this.time_id = time_id;
    this.start = start;
    this.end = end;
    this.flat_rate = flat_rate;
    this.rate_per_hour = rate_per_hour;
    this.pilot_id = pilot_id;
    this.title = title;
    this.color = color;
  }

  // getters
  getTimeId() {
    return this.time_id;
  }
  getStart() {
    return this.start;
  }
  getEnd() {
    return this.end;
  }
  getflat_rate() {
    return this.flat_rate;
  }

  getRatePerHour() {
    return this.rate_per_hour;
  }
  getpilot_id() {
    return this.pilot_id;
  }
  getTitle() {
    return this.title;
  }
  getColor() {
    return this.color;
  }

  // setters
  // setTimeId() {
  //   this.time_id = time_id;
  // }
  setStart(newStart) {
    this.start = newStart;
  }
  setEnd(newEnd) {
    this.end = newEnd;
  }
  setflat_rate(newflat_rate) {
    this.flat_rate = newflat_rate;
  }

  setRatePerHour(newRatePerHour) {
    this.rate_per_hour = newRatePerHour;
  }
  setpilot_id(newpilot_id) {
    this.pilot_id = newpilot_id;
  }
  setTitle(newTitle) {
    this.title = newTitle;
  }
  setColor(newColor) {
    this.color = newColor;
  }

  returnEvent() {
    // {
    //   title: "Big Meeting",
    //   allDay: true,
    //   start: new Date(2022, 6, 0),
    //   end: new Date(2022, 6, 0),
    // },

    let event = {
      title: this.getTitle(),
      // allDay: true,
      start: this.getStart(),
      end: this.getEnd(),
      flat_rate: this.getflat_rate(),
      rate_per_hour: this.getRatePerHour(),
      pilot_id: this.getpilot_id(),
      time_id: this.getTimeId(),
      color: this.getColor(),
    };
    return event;
  }
}
