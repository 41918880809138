import React, { useState, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";

import HeaderLoggedIn from "./HeaderLoggedIn";
import HeaderLoggedOut from "./HeaderLoggedOut";
import Brand from "./Brand";

import { AuthContext } from "../Auth";

const Header = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <Navbar bg="light" variant="light" expand="lg">
        <Container>
          <Brand />
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="nav-contents py-2 px-0 gap-2 d-flex width-100 align-items-center">
              {!!currentUser ? <HeaderLoggedIn /> : <HeaderLoggedOut />}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
