// import React from "react";
import React, { useCallback, useState, useEffect } from "react";
import app from "../Main/base";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ListItem,
  ListItemText,
  List,
  Button,
  Select,
  InputLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Calendar from "./EditPlane/Forms/Calendar";

import FormControl from "@material-ui/core/FormControl";
import { DropzoneArea } from "material-ui-dropzone";
import { useDropzone } from "react-dropzone";
import { FormControlLabel } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
// import { FirebaseAuthConsumer } from "@react-firebase/auth";
// import { createPlane } from "../hooks/planes";
import firebase from "firebase";
import { set } from "date-fns";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

import { InspectionInfo } from "./EditPlane/Forms/InspectionInfo";
import { Availibility } from "./EditPlane/Forms/Availibility";
import { CreatePlane } from "./CreatePlane";

import { getUserNameById } from "../Utils/Utils";
import PlaneTab from "./PlaneTab/PlaneTab";
import PlanePrepTableComponent from "./PlaneTab/PlanePrepTableComponent";

// One Accordian opens at a time
// https://stackoverflow.com/questions/68676510/only-allow-one-react-accordion-to-be-expanded-at-once
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  formControl: {
    width: "100%",
  },
  dropZone: {
    height: "10rem",
    display: "flex",
  },
}));

const ListPlane = (props) => {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (id) => (_, isExpanded) => {
    // if expanded, set id to open/expand, close it otherwise
    setExpanded(isExpanded ? id : null);
  };

  //const classes = useStyles();
  const [count, setCount] = React.useState(0);

  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [userName, setUserName] = React.useState("");

  // Trips
  const [loading, setLoading] = useState(true);
  const [myPlanes, setMyPlanes] = useState([]);

  useEffect(() => {
    getUserName();
    getMyPlanes();
  }, [loading]); //  Run once on load

  const getMyPlanes = () => {
    setMyPlanes([]);
    const getPlanesFromFirebase = [];
    const uid = app.auth().currentUser.uid;

    //setUserName(getUserNameById(uid));

    const db = app.firestore();
    const subscriber = db
      .collection("plane")
      .where("owner_id", "==", uid)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log("Plane ID ====> " + doc.id);
          getPlanesFromFirebase.push({
            ...doc.data(), //spread operator
            key: doc.id, // `id` given to us by Firebase
          });
        });
        setMyPlanes(getPlanesFromFirebase);
        setLoading(false);
      });

    return () => subscriber();
  };

  const getUserName = () => {
    const uid = app.auth().currentUser.uid;
    const db = app.firestore();
    const subscriber = db
      .collection("chatUsers")
      .doc(uid)
      .get()
      .then((u) => {
        if (!u.data() || u.data() == undefined){
          console.log(" u.data - ", u.data());
        }else{          
          setUserName(u.data().firstName + " " + u.data().lastName);
        }
        //setLoading(false);
      });

    return () => subscriber();
  };

  const your_plane_id = 1;
  const availibility_id = 2;
  const inspectionInfo_id = 3;

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    <div>
      <Container style={{ height: "100vh" }}>
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="Add New Plane" {...a11yProps(0)} />
              <Tab label="Plane Prep" {...a11yProps(1)} />
              <Tab label="Flight Ready" {...a11yProps(2)} />
              {/* <Tab label="Remove Plane" {...a11yProps(3)} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Typography component={"span"}>Add New Plane</Typography>
            <div>
              <CreatePlane
                key={your_plane_id} // <-- set React key here!!
              />
            </div>

            <div className="">{/* <h1>Answers:</h1> */}</div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography>Flight Prep</Typography>
            {/* <ApprovedTrips /> */}
            <div className="">
              <PlanePrepTableComponent
                myPlanes={myPlanes}
                userName={userName}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Typography>Flight Ready</Typography>
          </TabPanel>
        </div>
      </Container>
    </div>
  );
};

export default ListPlane;
