import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import DATA_IMAGE from "./defualtCarouselImage.json";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import PlaneDropzone from "../../../Utils/Dropzones/PlaneDropzone";
import app from "../../../Main/base";
import { getImageUrl } from "../../../Utils/Utils";
import ProgressBar from "react-bootstrap/ProgressBar";
import CarouselLayout from "./CarouselLayout";

export const CarouselComponent = ({ images, plane_id }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [plane, setPlane] = useState({});
  const [displayImages, setDisplayImages] = useState([]);

  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getPlane().then(getDisplayImages());
  }, [loading]); //  Run once on load

  const getPlane = async () => {
    const db = app.firestore();
    const subscriber = db
      .collection("plane")
      .doc(plane_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Set Plane to this Document data: ", doc.data());
          setPlane(doc.data());
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
        // setLoading(false);
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });

    return () => subscriber();
  };

  const getDisplayImages = async () => {
    let list = [];
    let notNullCount = 0;
    plane.display_images?.map((path, index) => {
      let item = getImageUrl(app.storage(), path);
      item.then((result) => {
        //setCurrentProfileImage(result.toString());
        // console.log("645 item 1 ------- ", result, " index => ", index);
        result == null ? notNullCount++ : (notNullCount = notNullCount + 0);
        list.push(result);
      });
    });
    setDisplayImages(list);
    let notNullable = images.filter(
      (obj) => !Object.values(obj).includes(null)
    ).length;
    setProgress(images.length / notNullable);
    setLoading(false);
  };

  return (
    <div>
      <CarouselLayout images={images} />
      {/* <ProgressBar variant="success" now={progress} className="mt-3" /> */}
      <Button
        className="mt-3 w-100"
        onClick={handleShow}
        variant="primary"
        size="lg"
      >
        Edit Photos
      </Button>

      <Offcanvas
        scroll={true}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Picture</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {plane.display_images?.map((image, index) => (
            <div>
              <PlaneDropzone
                index={index}
                plane_passing={plane}
                plane_id={plane_id}
                imagePath={image}
              />
              <br /> <br /> <br />
              <br /> <br /> <br />
              <br /> <br /> <br />
              <br /> <br /> <br />
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CarouselComponent;
