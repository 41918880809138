import React, { useState } from "react";
import CalendarTemplate from "./CalendarTemplate";

const Calendar = () => {
  const [availability, setAvailability] = useState([]);
  const Cal = CalendarTemplate({
    availability,
    setAvailability,
  });
  return (
    <div>
      <Cal />
    </div>
  );
};

export default Calendar;
