import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Badge,
  Button,
  Col,
  CloseButton,
  Form,
  Row,
  Spinner,
  Dropdown,
} from "react-bootstrap";

import app from "../../../Main/base";
import { setYears } from "../../../Utils/years";
import {
  updatePlane,
  createPlane,
  getLatLngGeoHash,
} from "../../../Utils/Utils";
import { defaultPlane } from "../../../Utils/defaultPlane";
import axios from "axios";

import firebase from "firebase";

export const PlaneForm = ({ existingPlane, planeId }) => {
  const years = setYears();
  const [avionic, setAvionic] = useState("");
  const [avionics, setAvionics] = useState(existingPlane?.avionics || []);
  const [predictions, setPredictions] = useState([]);
  const [airport, setAirport] = useState([{ name: "" }]);
  const [showDropDown, setDropDown] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: existingPlane ? existingPlane : defaultPlane,
  });

  const getPredictions = (value) => {
    // let's say that it's an API call
    let array = airport.map((a) => a.name);
    return array
      .filter((item) => item.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      .slice(0, 6);
  };

  const onAutoFill = (e) => {
    // clear timeout when input changes value
    if (e.target.value.length > 0) {
      // make delayed api call
      setDropDown(true);
      setPredictions(getPredictions(e.target.value));
    } else {
      setDropDown(false);
      setPredictions([]);
    }
  };

  const onSubmit = async (data) => {
    const needCheckout = data.need_preflight_checkout === "true" ? true : false;
    const hobbs_time = parseFloat(data.hobbs_time);
    const price_perhour_usd = parseFloat(data.price_perhour_usd);

    const [lat, lng, geohash_var] = await getLatLngGeoHash(data);

    console.log("finished res => ", lat, lng, geohash_var);
    const plane = {
      ...data,
      avionics,
      need_preflight_checkout: needCheckout,
      hobbs_time,
      price_perhour_usd,
      geo_point: new firebase.default.firestore.GeoPoint(lat, lng),
      geohash: geohash_var,
      recurring_time: [
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "SUN",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "MON",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "TUE",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "WED",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "THU",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "FRI",
          color: "lightblue",
        },
        {
          start: new Date(1970, 0, 1),
          end: new Date(1970, 0, 1),
          flat_rate: 0,
          rate_per_hour: 0,
          pilot_id: "",
          title: "SAT",
          color: "lightblue",
        },
      ],
    };

    if (existingPlane && planeId) {
      try {
        await updatePlane(plane, planeId);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const pid = app.firestore().collection("plane").doc().id;
        await createPlane(plane, pid);
        reset();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAvionics = () => {
    if (!avionic) return;
    setAvionics((prev) => [...prev, avionic]);
    setAvionic("");
  };

  useEffect(() => {
    axios
      .get(
        // "https://airlabs.co/api/v9/airports?country_code=US&api_key=4c34360f-a9f7-4910-9b17-50ea23f857bd"
        "https://airlabs.co/api/v9/airports?country_code=US&api_key=1d916a62-f5f5-4301-b11d-34751f75612d"
      )
      .then(function (response) {
        setAirport(response.data.response);
        console.log(airport);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="px-3 pb-5">
      <p>Where is your plane located?</p>
      <Form.Group className="mb-3">
        <Form.Label>Address</Form.Label>

        <Form.Control
          id="base_plane_location"
          isInvalid={!!errors.base_plane_location}
          {...register("base_plane_location", {
            onChange: onAutoFill,
            required: "Address is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3 characters",
            },
          })}
        />
        {showDropDown && (
          <Dropdown.Menu show>
            {predictions.map((item) => (
              <Dropdown.Item
                key={item}
                onClick={(e) => {
                  console.log(e.target.innerHTML);
                  document.getElementById("base_plane_location").value =
                    e.target.innerHTML;
                  setDropDown(false);
                  setPredictions([]);
                }}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        <Form.Text className="text-danger">
          {errors.base_plane_location?.message}
        </Form.Text>
      </Form.Group>
      <Row className="mb-1">
        <Form.Group as={Col} xs={12} sm={6} md={3}>
          <Form.Label>Make</Form.Label>
          <Form.Control
            className="mb-3"
            isInvalid={!!errors.make}
            {...register("make", { required: "Make is required" })}
          />
          <Form.Text className="text-danger">{errors.make?.message}</Form.Text>
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} md={3}>
          <Form.Label>Model</Form.Label>
          <Form.Control
            className="mb-3"
            isInvalid={!!errors.model}
            {...register("model", {
              required: "Model is required",
            })}
          />
          <Form.Text className="text-danger">{errors.model?.message}</Form.Text>
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} md={3}>
          <Form.Label>Year</Form.Label>
          <Form.Select
            className="mb-3"
            isInvalid={!!errors.year}
            aria-label="Years"
            {...register("year", {
              required: "Year is required",
            })}
          >
            {years.map((e, key) => (
              <option value={e} key={key} label={e}>
                {e}
              </option>
            ))}
            <Form.Text className="text-danger">
              {errors.year?.message}
            </Form.Text>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label xs={12} sm={6} md={3}>
            Hobbs Time
          </Form.Label>
          <Form.Control
            type="number"
            className="mb-3"
            isInvalid={!!errors.hobbs_time}
            {...register("hobbs_time", {
              required: "Hobbs time is required",
            })}
          />
          <Form.Text className="text-danger">
            {errors.hobbs_time?.message}
          </Form.Text>
        </Form.Group>
      </Row>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column xs={12} sm={9} md={6}>
          Does this plane need a preflight checkout?
        </Form.Label>
        <Col>
          <Form.Select
            aria-label="Pre Flight Checkout"
            {...register("need_preflight_checkout")}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column xs={12} sm={3} md={6}>
          Category
        </Form.Label>
        <Col>
          <Form.Select
            aria-label="Category"
            isInvalid={!!errors.category}
            {...register("category", {
              required: "Category is required",
            })}
          >
            <option value="Single Engine Airplane (Land)">
              Single Engine Airplane (Land)
            </option>
            <option value="Multi Engine Airplane (Land)">
              Multi Engine Airplane (Land)
            </option>
            <option value="Single Engine Airplane (Sea)">
              Single Engine Airplane (Sea)
            </option>
            <option value="Multi Engine Airplane (Sea)">
              Multi Engine Airplane (Sea)
            </option>
            <option value="Rotorcraft">Rotorcraft</option>
            <option value="Glider">Glider</option>
            <option value="Lighter than air">Lighter than air</option>
            <option value="UAV">UAV</option>
          </Form.Select>
          <Form.Text className="text-danger">
            {errors.category?.message}
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          isInvalid={!!errors.description}
          as="textarea"
          rows={3}
          {...register("description", {
            required: "Description is required",
          })}
        />
        <Form.Text className="text-danger">
          {errors.description?.message}
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Rules and Guidelines</Form.Label>
        <Form.Control
          isInvalid={!!errors.rules_guidelines}
          as="textarea"
          rows={3}
          {...register("rules_guidelines", {
            required: "Rules and guidelines are required",
          })}
        />
        <Form.Text className="text-danger">
          {errors.rules_guidelines?.message}
        </Form.Text>
      </Form.Group>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group>
            <Form.Label>Price Per Hour</Form.Label>
            <Form.Control
              className="mb-3"
              type="number"
              isInvalid={!!errors.price_perhour_usd}
              {...register("price_perhour_usd", {
                required: "Price per hour is required",
              })}
            />
            <Form.Text className="text-danger">
              {errors.price_perhour_usd?.message}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group>
            <Form.Label>N-Number</Form.Label>
            <Form.Control
              className="mb-3"
              isInvalid={!!errors.n_number_id}
              {...register("n_number_id", {
                required: "N-Number id is required",
              })}
            />
            <Form.Text className="text-danger">
              {errors.n_number_id?.message}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <div className="py-4">
        <h5 className="text-primary ">
          Avionics{" "}
          {avionics.map((a, i) => (
            <div key={i} className="mx-2  d-inline-flex flex-wrap mb-3">
              <Badge
                className="d-inline-flex align-items-center"
                bg="secondary"
              >
                {a}
                <CloseButton
                  style={{ marginLeft: 16 }}
                  aria-label="Remove"
                  variant="white"
                  onClick={() =>
                    setAvionics((prev) => prev.filter((av) => av !== a))
                  }
                />
              </Badge>
            </div>
          ))}
        </h5>
        <Form.Group as={Row}>
          <Form.Label column xs={12} sm={3} md={2}>
            Add Avionic
          </Form.Label>
          <Col xs={12} sm={9} md={8} lg={6} xl={4}>
            <Form.Control
              className="mb-3"
              value={avionic}
              onChange={(e) => setAvionic(e.target.value)}
            />
            <Form.Text className="text-danger"></Form.Text>
          </Col>
          <Col>
            <Button onClick={handleAvionics}>Add</Button>
          </Col>
        </Form.Group>
      </div>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <>
            Saving...
            <Spinner style={{ marginLeft: 8 }} animation="border" size="sm" />
          </>
        ) : existingPlane ? (
          "Update Plane"
        ) : (
          "Save Plane"
        )}
      </Button>
    </Form>
  );
};
