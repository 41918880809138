// import React from "react";
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import app from "../../../Main/base";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ListItem,
  ListItemText,
  List,
  Select,
  InputLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Calendar from "./Calendar";

import FormControl from "@material-ui/core/FormControl";
import { DropzoneArea } from "material-ui-dropzone";
import { useDropzone } from "react-dropzone";
import { FormControlLabel } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
// import { FirebaseAuthConsumer } from "@react-firebase/auth";
// import { createPlane } from "../hooks/planes";
import firebase from "firebase";
import { set } from "date-fns";

import { getImageUrl, reload, handleUpload } from "../../../Utils/Utils";

import MyDropzone from "../../../Utils/Dropzones/MyDropzone";

import DATA_IMAGE from "./defualtCarouselImage.json";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import PlaneDropzone from "../../../Utils/Dropzones/PlaneDropzone";
// import app from "../../../Main/base";
import CarouselLayout from "./CarouselLayout";

// import "../../../../layouts/layout-md.css";
import ProgressBar from "react-bootstrap/ProgressBar";

import "./InspectionInfo.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  // list: {
  //   display: "flex",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   justifyContent: "space-evenly",
  //   // flexGrow: "1",
  // },
  item: {
    width: "33%",
    paddingBottom: "15%",
  },
  formControl: {
    //width: "100%",
  },
  dropZone: {
    height: "100%",
    width: "100%",
    display: "flex",
    // backgroundColor: "lightblue",
  },

  flexContainerItem: {
    backgroundColor: "#f1f1f1",
    width: "100px",
    margin: "10px",
    textAlign: "center",
    lineHeight: "75px",
    fontSize: "30px",
  },
}));

const Years = () => {
  const options = [];
  const date = new Date();
  for (let i = date.getFullYear(); i >= 1900; i--) {
    options.push(i);
  }
  // console.log(date.getFullYear());
  return options;
};

const RenderAvionics = (props) => {
  let { avionics } = props;
  let avionicsList = avionics.map((a, key) => {
    return (
      <ListItem>
        <ListItemText key={key}>
          <Typography>{a}</Typography>
        </ListItemText>
      </ListItem>
    );
  });
  return avionicsList;
};

export const InspectionInfo = ({ images, plane_id }) => {
  const classes = useStyles();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [plane, setPlane] = useState({});

  const [defaultImage, setDefaultImage] = useState("");

  const [inspectionImages, setInspectionImages] = useState({ array: [] });

  const [loading, setLoading] = useState(true);

  const [displayImages, setDisplayImages] = useState([]);

  const [progress, setProgress] = useState(0);

  //const parentSumbitFunc = useState(null);
  const parentSumbitFunc1 = useRef(null);
  const parentSumbitFunc2 = useRef(null);
  const parentSumbitFunc3 = useRef(null);

  const parentSumbitFunc4 = useRef(null);
  const parentSumbitFunc5 = useRef(null);
  const parentSumbitFunc6 = useRef(null);

  const parentSumbitFunc7 = useRef(null);
  const parentSumbitFunc8 = useRef(null);
  const parentSumbitFunc9 = useRef(null);

  const parentSumbitFunc10 = useRef(null);

  useEffect(() => {
    getPlane();
  }, [loading]); //  Run once on load

  const getData = () => {};

  const getPlane = async () => {
    const db = app.firestore();
    const subscriber = db
      .collection("plane")
      .doc(plane_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Set Plane to this Document data: ", doc.data());
          setPlane(doc.data());
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });

    return () => subscriber();
  };

  const getDisplayImages = async () => {
    let list = [];
    let notNullCount = 0;
    plane.display_images?.map((path, index) => {
      let item = getImageUrl(app.storage(), path);
      item.then((result) => {
        //setCurrentProfileImage(result.toString());
        // console.log("645 item 1 ------- ", result, " index => ", index);
        list.push(result);
      });
    });
    setDisplayImages(list);
    setProgress(list.length / notNullCount);
    setLoading(false);
  };

  const [waiting, setWaiting] = useState(true);
  const handleImageSubmit = async () => {
    parentSumbitFunc1.current();
    parentSumbitFunc2.current();
    parentSumbitFunc3.current();

    parentSumbitFunc4.current();
    parentSumbitFunc5.current();
    parentSumbitFunc6.current();

    parentSumbitFunc7.current();
    parentSumbitFunc8.current();
    parentSumbitFunc9.current();

    parentSumbitFunc10.current();
  };

  let years = Years();
  // console.log(years);

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    <div>
      <CarouselLayout images={images} />
      <Button
        className="mt-3 w-100"
        onClick={handleShow}
        variant="primary"
        size="lg"
      >
        Update Inspections Photos
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Inspection Picture</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {plane.inspection_images?.map((imageObj, index) => (
            <div>
              <h4>{imageObj.name}</h4>
              <MyDropzone
                index={index}
                plane_passing={plane}
                plane_id={plane_id}
                imagePath={imageObj.image_path}
                parentSumbitFunc={parentSumbitFunc1}
              />
              <br /> <br /> <br />
              <br /> <br /> <br />
              <br /> <br /> <br />
              <br /> <br /> <br />
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
      <br /> <br /> <br />
    </div>
  );
};

export default InspectionInfo;
