// import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Map, { GeolocateControl, useMap } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxAutocomplete from "react-mapbox-autocomplete";

import app from "../Main/base";
import {
  lettersOnly,
  numbersOnly,
  sanitizePhone,
  validEmail,
  validPhone,
} from "../Utils/validation";

const states = {
  Alabama: "AL",
  Alaska: "AK",
  AmericanSamoa: "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  ArmedForcesAmericas: "AA",
  ArmedForcesEurope: "AE",
  ArmedForcesPacific: "AP",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  DistrictOfColumbia: "DC",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  MarshallIslands: "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  NewHampshire: "NH",
  NewJersey: "NJ",
  NewMexico: "NM",
  NewYork: "NY",
  NorthCarolina: "NC",
  NorthDakota: "ND",
  NorthernMarianaIslands: "NP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  PuertoRico: "PR",
  RhodeIsland: "RI",
  SouthCarolina: "SC",
  SouthDakota: "SD",
  Tennessee: "TN",
  Texas: "TX",
  USVirginIslands: "VI",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  WestVirginia: "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

const EditProfileButton = ({ uid, user }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mapRef = useRef();

  const _suggestionSelect = (result, lat, lng, text) => {
    mapRef.current.flyTo({ center: [lng, lat], zoom: 15 });
    const address = result.split(", ");
    setValue("country", address[address.length - 1]);
    var zipcode = address[address.length - 2].match(/(\d+)/);
    var state = address[address.length - 2].replace(/[^A-Za-z]/g, "");
    console.log("zipcode", state);
    setValue("zipcode", zipcode[0]);
    setValue("state", states[state]);
    setValue("city", address[address.length - 3]);
    setValue("streetAddress", address[address.length - 4]);
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: {
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      city: user?.city || "",
      country: user?.country || "",
      state: user?.state || "",
      streetAddress: user?.streetAddress || "",
      userName: user?.userName || "",
      zipcode: user?.zipcode || "",
    },
  });

  const onSubmit = async (data) => {
    const updatedUser = {
      ...user,
      ...data,
      phoneNumber: sanitizePhone(data.phoneNumber),
      state: data.state.toUpperCase(),
    };

    try {
      await app
        .firestore()
        .collection(`chatUsers`)
        .doc(uid)
        .set(updatedUser, { merge: true });
      handleClose();

      // const chatData = {
      //   username: "adam_la_morre",
      //   email: "adam_la_morre@chatengine.io",
      //   first_name: "Adam",
      //   last_name: "La Morre",
      //   custom_json: {
      //     custom_json: 2001,
      //   },
      // };
      // let private_id = "86140ca3-c1f1-4ff0-9332-8cc433ce3361";
      // axios
      //   .patch(`https://api.chatengine.io/users/123/`, data, {
      //     headers: {
      //       "PRIVATE-KEY": private_id,
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response.data);
      //   })
      //   .catch((error) => {
      //     console.log("Edit Myself Error", error);
      //   });
      // console.log("Document successfully updated!");
      // setTimeout(function () {
      //   window.location.reload();
      // }, 47000);
    } catch (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    }
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Edit profile
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                isInvalid={!!errors.userName}
                {...register("userName", {
                  required: "User name is required",
                })}
              />
              <Form.Text className="text-danger">
                {errors.userName?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                isInvalid={!!errors.email}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: validEmail,
                    message: `That doesn't look like a valid email`,
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.email?.message}
              </Form.Text>
            </Form.Group>
            <Map
              ref={mapRef}
              mapboxAccessToken={process.env.REACT_APP_API_KEY}
              initialViewState={{
                longitude: -100,
                latitude: 40,
                zoom: 3.5,
              }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              style={{ width: "100%", height: 400, "margin-bottom": 10 }}
            >
              <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
              />
            </Map>
            <MapboxAutocomplete
              publicKey={process.env.REACT_APP_API_KEY}
              inputClass="form-control search"
              onSuggestionSelect={_suggestionSelect}
              country="us"
              resetSearch={false}
            />
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                isInvalid={!!errors.phoneNumber}
                {...register("phoneNumber", {
                  required: "Please provide your phone",
                  pattern: {
                    value: validPhone,
                    message: `Invalid phone number`,
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.phoneNumber?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                isInvalid={!!errors.city}
                {...register("city", {
                  required: "City is required",
                })}
              />
              <Form.Text className="text-danger">
                {errors.city?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                isInvalid={!!errors.country}
                {...register("country", {
                  required: "Country is required",
                })}
              />
              <Form.Text className="text-danger">
                {errors.country?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                isInvalid={!!errors.state}
                {...register("state", {
                  required: "State is required",
                  minLength: {
                    value: 2,
                    message: "Minimum length is 2 characters",
                  },
                  maxLength: {
                    value: 2,
                    message: "Maximum length is 2 characters",
                  },
                  pattern: {
                    value: lettersOnly,
                    message: "Only letters allowed",
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.state?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                isInvalid={!!errors.streetAddress}
                {...register("streetAddress", {
                  required: "Street address is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length is 4 characters",
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.streetAddress?.message}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control
                isInvalid={!!errors.zipcode}
                {...register("zipcode", {
                  required: "Zipcode is required",
                  minLength: {
                    value: 5,
                    message: "Minimum length is 5 digits",
                  },
                  maxLength: {
                    value: 5,
                    message: "Maximum length is 5 digits",
                  },
                  pattern: {
                    value: numbersOnly,
                    message: "Only numbers allowed",
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.zipcode?.message}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isSubmitting}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={() => handleSubmit(onSubmit)()}
          >
            {isSubmitting ? (
              <>
                Saving...
                <Spinner
                  style={{ marginLeft: 8 }}
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              "Save Profile"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProfileButton;
