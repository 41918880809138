// import React from "react";
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
// import app from "../../../Main/base";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "react-bootstrap/Card";

import { useDropzone } from "react-dropzone";

import app from "../../Main/base";
import { getImageUrl, reload, handleUpload } from "../Utils";
import ProgressBar from "react-bootstrap/ProgressBar";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import set from "date-fns/set";
// import datePickerSnippet from "./datePicker";

import Form from "react-bootstrap/Form";
import { FormLabel } from "@material-ui/core";
import DatePicker from "react-datepicker";

import Button from "react-bootstrap/Button";

import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    // flexGrow: "1",
  },
  item: {
    width: "33%",
  },
  formControl: {
    //width: "100%",
  },
  dropZone: {
    height: "10rem",

    display: "flex",
    flexDirection: "column",
  },
  dateFormat: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "5px",
  },
}));

export default function ProfileDropzone({ imagePath, user_id }) {
  const [loading, setLoading] = useState(true);

  function alertUser() {
    console.log("You clicked! from path => ", imagePath);
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();

  const [image, setImage] = useState(null);
  const [state, setState] = useState(null);
  const [changed, setChanged] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  //Migth look at setState to see if that is null
  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setState(reader.result);
        setImage(e.target.files[0]);
        setChanged(true);
        setIsDefault(false);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    // setChanged(true);
    setProgress(0);
    // console.log("changed: ", changed);
  };

  const setProfileImages = () => {
    let item = getImageUrl(app.storage(), imagePath);
    item.then((result) => {
      if (result != null) {
        setState(result);
        setProgress(100);

        // setLoading(false);
      } else {
        let path = getImageUrl(app.storage(), "/users/default/default.jpg");
        path.then((res) => {
          setState(res);
          setIsDefault(true);
          //   setLoading(false);
        });
      }
    });
    // .then(() => {
    //   var timestamp = Date.parse(
    //     plane_passing.inspection_images[index]["date"]
    //   );
    //   let uploadedDate = new Date(
    //     plane_passing.inspection_images[index]["date"].seconds * 1000
    //   );
    //   setStartDate(uploadedDate);
    // });

    setLoading(false);
  };

  useEffect(() => {
    //   parentSumbitFunc.current = handleImageSubmit;
    //   parentSumbitFunc.current = handleImageSubmit;

    setProfileImages();
  }, [loading]);

  // Submitting

  const [waiting, setWaiting] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleImageSubmit = async () => {
    // console.log("Image Path ==> ", imagePath);
    if (progress == 0 && changed == true) {
      // console.log("passed if changed ");
      const uploadTask = app.storage().ref(imagePath).put(image);

      uploadTask.on(
        "state_change",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // console.log("Upload is " + progress + "% done");
          setProgress(progress);
        },
        (error) => {
          // console.log(error);
        },
        async () => {
          let imgURL = await uploadTask.snapshot.ref.getDownloadURL();
          // console.log("uploaded image: " + imgURL.toString());
        }
      );
      //await updatePlane(plane, user_id);
      //await up
      reload();
    }
  };

  const handleImageDelete = async () => {
    // Delete the file
    app
      .storage()
      .ref(imagePath)
      .delete()
      .then(() => {
        // File deleted successfully
        // console.log("File deleted successfully");
        setProfileImages();
        setProgress(0);
        // reload();
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        // console.log(error);
      });
    reload();
  };

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    <Paper className={classes.dropZone}>
      <div {...getRootProps()}>
        <input {...getInputProps()} onChange={handleImageChange} />

        <div
          style={{
            width: "100%",
            height: "15rem",
          }}
        >
          {" "}
          <img
            src={state}
            style={{
              height: "100%",
              width: "100%",
            }}
          ></img>
        </div>

        <ProgressBar variant="success" now={progress} />
      </div>
      <br />

      <Button
        className="reg-mb-10"
        variant="primary"
        onClick={handleImageSubmit}
      >
        Upload image
      </Button>
      <Button
        className="reg-mb-10"
        variant="warning"
        onClick={handleImageDelete}
      >
        Delete image
      </Button>
    </Paper>
  );
}
