import React, { useEffect, useRef, useState } from "react";
import "./SearchComponent.scss";
import { Search, GpsFixed } from "@material-ui/icons";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { AddressAutofill } from "@mapbox/search-js-react";

// const geocoder = new MapboxGeocoder({
//   accessToken:
//     "pk.eyJ1IjoiNDRuYXNoIiwiYSI6ImNrbnFvMjY1dDBjMGQydnFvNjNtZ2p2am8ifQ.G61N5C3tvokOBxfamQCC4g",
//   mapboxgl: mapboxgl,
// });

function SearchComponent() {
  const [finalInputAddress, setFinalInputAddress] = useState(
    "65%20Ann%20Street%20Michigan"
  );

  const [value, setValue] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");

  const [respone, setResponse] = useState(null);

  // const handleInputChange = (text) => {
  //   setInputValue(text);
  // };

  const [searchTerm, setSearchTerm] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const mapBoxSearch = (searchTerm) => {
    const address = searchTerm.replaceAll(" ", "%20");
    // must hide access token
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=US&types=address&access_token=pk.eyJ1IjoiNDRuYXNoIiwiYSI6ImNrbnFvMjY1dDBjMGQydnFvNjNtZ2p2am8ifQ.G61N5C3tvokOBxfamQCC4g`
      )
      .then((res) => {
        const list = res.data;
        //console.log("mapbox list => ", res.data);
        setSearchResults(res.data.features);
        //setResponse({ list });
      });
  };

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  // 1. Try to clear the search text and update the
  // Url to what was searched

  // 2. Onclick get my current location
  const history = useHistory();
  const handleKeyDown = (event, address) => {
    if (event.key === "Enter") {
      setSearchResults([]);
      setSearchTerm("");
      history.push(`/search/${finalInputAddress}`);
    }
  };
  const handleOnClick = (event) => {
    // console.log(event.target.value);
    setSearchResults([]);
    setSearchTerm("");
    //history.push(`/search/${finalInputAddress}`);
    let address = `${value} ${city} ${state} ${country} ${postalCode}`;
    const searchText = address.replaceAll(" ", "%20");
    setValue("");
    history.push(`/search/${searchText}`);
  };

  const handleEnterKeyDown = (event) => {
    console.log("event.key", event.key);
    if (event.key === "Enter") {
      // console.log(event.target.value);
      setSearchResults([]);
      setSearchTerm("");
      //history.push(`/search/${finalInputAddress}`);
      let address = `${value} ${city} ${state} ${country} ${postalCode}`;
      const searchText = address.replaceAll(" ", "%20");
      setValue("");
      history.push(`/search/${searchText}`);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      // Send Axios request here
      mapBoxSearch(searchTerm);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    // <div>
    <InputGroup>
      <InputGroup.Text id="basic-addon1" onClick={handleOnClick}>
        <Search />
      </InputGroup.Text>
      <Form className="flex-fill">
        {/*  onKeyDown={handleEnterKeyDown} */}
        <AddressAutofill
          accessToken={
            "pk.eyJ1IjoiNDRuYXNoIiwiYSI6ImNrbnFvMjY1dDBjMGQydnFvNjNtZ2p2am8ifQ.G61N5C3tvokOBxfamQCC4g"
          }
          options={{
            language: "en",
            country: "US",
          }}
          theme={{
            variables: {
              colorPrimary: "",
            },
          }}
        >
          <Form.Control
            className=""
            autoComplete="address-line1"
            value={value}
            onChange={(e) => {
              console.log(e.target.value);
              setValue(e.target.value);
            }}
            onKeyDown={handleEnterKeyDown}
          />
        </AddressAutofill>
        {/* <input
        className="hidden"
          name="apartment"
          placeholder="Apartment number"
          type="text"
          autoComplete="address-line2"
        /> */}
        <input
          className="hidden"
          name="city"
          placeholder="City"
          type="text"
          autoComplete="address-level2"
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />
        <input
          className="hidden"
          name="state"
          placeholder="State"
          type="text"
          autoComplete="address-level1"
          onChange={(e) => {
            setState(e.target.value);
          }}
        />
        <input
          className="hidden"
          name="country"
          placeholder="Country"
          type="text"
          autoComplete="country"
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
        <input
          className="hidden"
          name="postcode"
          placeholder="Postcode"
          type="text"
          autoComplete="postal-code"
          onChange={(e) => {
            setPostalCode(e.target.value);
          }}
        />
      </Form>
      {/* <InputGroup.Text id="basic-addon1"> */}
      {/* On click open map in my location */}
      {/* <GpsFixed /> */}
      {/* </InputGroup.Text> */}
    </InputGroup>

    //   <div className="dropdown-content width-26">
    //     <div className="">
    //       <InputGroup className="search-bar">
    //         <InputGroup.Text id="basic-addon1" onClick={handleOnClick}>
    //           <Search />
    //         </InputGroup.Text>
    //         <Form.Control
    //           type="text"
    //           placeholder="Search location...."
    //           onKeyDown={handleKeyDown}
    //           aria-label="Username"
    //           aria-describedby="basic-addon1"
    //           onChange={(e) => setSearchTerm(e.target.value)}
    //         />
    //         <InputGroup.Text id="basic-addon1">
    //           <GpsFixed />
    //         </InputGroup.Text>
    //       </InputGroup>
    //       {searchResults.map((item, i) => (
    //         <li
    //           className="reg-flex-start"
    //           onClick={(item) => {
    //             setFinalInputAddress(item.place);
    //             handleOnClick();
    //           }}
    //           key={i}
    //         >
    //           {item.place_name}
    //         </li>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );
}

export default SearchComponent;
