// import React from "react";
import React, { useCallback, useState } from "react";
import app from "../../../Main/base";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ListItem,
  ListItemText,
  List,
  Button,
  Select,
  InputLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Calendar from "./Calendar";

import FormControl from "@material-ui/core/FormControl";
import { DropzoneArea } from "material-ui-dropzone";
import { useDropzone } from "react-dropzone";
import { FormControlLabel } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
// import { FirebaseAuthConsumer } from "@react-firebase/auth";
// import { createPlane } from "../hooks/planes";
import firebase from "firebase";
import { set } from "date-fns";
import CalendarComponent from "../../../Utils/Calendars/CalendarComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  formControl: {
    width: "100%",
  },
  dropZone: {
    height: "10rem",
    display: "flex",
  },
}));
function MyDropzone() {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();
  return (
    <Paper className={classes.dropZone}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
    </Paper>
  );
}
const Years = () => {
  const options = [];
  const date = new Date();
  for (let i = date.getFullYear(); i >= 1900; i--) {
    options.push(i);
  }
  // console.log(date.getFullYear());
  return options;
};

const RenderAvionics = (props) => {
  let { avionics } = props;
  let avionicsList = avionics.map((a, key) => {
    return (
      <ListItem>
        <ListItemText key={key}>
          <Typography>{a}</Typography>
        </ListItemText>
      </ListItem>
    );
  });
  return avionicsList;
};

export const Availibility = ({ expanded, onChange, plane_id }) => {
  const classes = useStyles();

  let years = Years();
  // console.log(years);
  return (
    <div>
      <List className={classes.list}>
        <ListItem>
          <ListItemText>
            <Typography>What is your plane's Availibility</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {/* <Calendar /> */}
            <CalendarComponent plane_id={plane_id} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Submit />
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

const Submit = (props) => {
  return (
    <Button variant="contained" color="primary">
      Save
    </Button>
  );
};

export default Availibility;
