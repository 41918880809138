import React, { useState, useContext } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import { Row, Col, Card, CardGroup, ListGroup } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
// Components
import { Login } from "./Login";
import { Signup } from "./Signup";

// ---------- Tabs ----------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStylesTab = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    transition: "position 500ms ease",
  },
}));

// ---------- Tabs ----------

const HeaderLoggedOut = () => {
  const [show, setShow] = useState(false);

  const theme = useTheme();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [value, setValue] = React.useState(0);
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <InputGroup>
        <InputGroup.Text id="basic-addon1">
          <SearchIcon />
        </InputGroup.Text>
        <FormControl
          style={{ backgroundColor: "white" }}
          aria-label="Text input with checkbox"
          placeholder="City, airport, address. or hotel..."
        />
      </InputGroup>
      <Button className="btn btn-primary text-nowrap" onClick={handleShow}>
        Login/Sign Up
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Sign Up" {...a11yProps(1)} />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
          <div>
            <SwipeableViews
              axis={"x-reverse"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {/* Item One */}
                <Login />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {/* Item Two */}
                <Signup />
              </TabPanel>
            </SwipeableViews>
            {/* <Tabs
                  defaultActiveKey="login"
                  className="mb-3"
                  style={{ display: "inline-block" }}
                >
                  <Tab eventKey="login" title="LOGIN">
                    <Login />
                  </Tab>
                  <Tab eventKey="signup" title="SIGNUP">
                    <Signup />
                  </Tab>
                </Tabs> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HeaderLoggedOut;
