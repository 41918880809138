import firebase from "firebase/app"; // <---- This must be first
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Spinner } from "react-bootstrap";
import "./Signup.css";
import { validEmail } from "../../../Utils/validation";
// import firebaseConfig from '../../firebaseConfig';

export const Signup = () => {
  // firebase.initializeApp(firebaseConfig);

  if (!firebase.apps.length) {
    firebase.initializeApp({});
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const fb = {
    auth: firebase.auth(),
    storage: firebase.storage(),
    firestore: firebase.firestore(),
  };
  const history = useHistory();
  const [serverError, setServerError] = useState("");

  const onSubmit = ({ email, userName, password }) => {
    function emailId(str) {
      const myArr = str.split("@");
      return myArr[0];
    }

    fb.auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        let emailIdVar = emailId(res.user.email);
        if (res?.user?.uid) {
          // bug that it doesnt create a new user
          // I think this should work
          // once the chat engnie part is back
          // Says API calls to chatengine no longer work, and to add lLight Plan for 7$
          axios
            .post(
              "https://api.chatengine.io/users/",
              { username: userName, secret: res.user.uid }, // Body object
              {
                headers: {
                  "Private-Key": "86140ca3-c1f1-4ff0-9332-8cc433ce3361",
                },
              } // Headers object
            )
            .then((r) => console.log(r))
            .then(() => {
              fb.firestore.collection("chatUsers").doc(res.user.uid).set({
                userName: emailIdVar,
                avatar: "",
                firstName: userName,
                lastName: "",
                state: "",
                zipcode: "",
                streetAddress: "",
                city: "",
                country: "",
                email: res.user.email,
                phoneNumber: "",
                my_planes: [],
                my_trips_as_owner: [],
                my_trips_as_pilot: [],
                ratings_of_me: [],
                ratings_of_other: [],
              });
            });
        } else {
          setServerError(
            "We're having trouble signing you up. Please try again."
          );
        }
        history.push("/profile");
      })
      .catch((err) => {
        if (err.code === "auth/email-already-in-use") {
          setServerError("An account with this email already exists");
        } else {
          setServerError(
            "We're having trouble signing you up. Please try again."
          );
        }
      });
  };

  // const createUser = async (req, res) => {
  //   const { userId, userName } = req.body;

  //   axios
  //     .post(
  //       'https://api.chatengine.io/projects/people/',
  //       { username: userName, secret: userId },
  //       { headers: { 'Private-Key': process.env.chat_engine_private_key } },
  //     )
  //     .then(apisRes => {
  //       res.json({
  //         body: apisRes.body,
  //         error: null,
  //       });
  //     })
  //     .catch(() => {
  //       res.json({
  //         body: null,
  //         error: 'There was an error',
  //       });
  //     });
  // };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: {
      email: "",
      password: "",
      username: "",
      verifyPassword: "",
    },
  });

  return (
    <div className="auth-form">
      <h1 style={{ color: "white" }}>Signup</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            isInvalid={!!errors.username}
            {...register("username", {
              required: true,
              pattern: {
                value: /^\S*$/,
                message: "No spaces allowed",
              },
            })}
          />
          <Form.Text className="text-danger">
            {errors.username?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={!!errors.email}
            {...register("email", {
              required: true,
              pattern: {
                value: validEmail,
                message: `That doesn't look like a valid email`,
              },
            })}
          />
          <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!errors.password}
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: "Minimum length is 8 characters",
              },
              maxLength: {
                value: 20,
                message: "Maximum length is 20 characters",
              },
            })}
          />
          <Form.Text className="text-danger">
            {errors.password?.message}
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Verify Password</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!errors.verifyPassword}
            {...register("verifyPassword", {
              required: true,
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Your passwords don't match";
                }
              },
            })}
          />
          <Form.Text className="text-danger">
            {errors.verifyPassword?.message}
          </Form.Text>
        </Form.Group>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              Sign Up
              <Spinner style={{ marginLeft: 8 }} animation="border" size="sm" />
            </>
          ) : (
            "Sign Up"
          )}
        </Button>
      </Form>

      {!!serverError && <div className="error">{serverError}</div>}
    </div>
  );
};
