import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./layouts/layout-md.scss";
import "./layouts/layout-reg.scss";
import "./layouts/layout-sm.scss";
import "./layouts/test.scss";
import "mapbox-gl/dist/mapbox-gl.css";

// import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
