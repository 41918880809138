import React from "react";
import Box from "@material-ui/core/Box";
import PlaneCard from "./PlaneCard";

import defualtImage from "../../images/mr.p.jpg";

const item = {
  title: "Cesna Citation",
  location: "Elkton, MD",
  coverImage:
    "https://upload.wikimedia.org/wikipedia/commons/6/69/N975QS_2002_Cessna_750_C-N_750-0175_Citation_X_%287039507775%29.jpg",
  hostRating: 4.7,
  price: 500,
};
function PlaneGrid({ planesList, planesIds }) {
  const getPlaneId = (image_path) => {
    let image_path_parts = image_path.split("/");
    return image_path_parts[2];
  };
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(75px, 1fr))"
      gridGap={16}
      alignItems="stretch"
    >
      {planesList.map((planeObj, index) => (
        <Box gridColumn="span 4">
          {/* planeId={planesIds[index]} */}
          {/* planeObj.fields.display_images.arrayValue.values[0].stringValue */}
          <PlaneCard
            planeObj={planeObj}
            planeId={getPlaneId(
              planeObj.fields.display_images.arrayValue.values[0].stringValue
            )}
          />
        </Box>
      ))}
    </Box>
  );
}

export default PlaneGrid;
