import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TripChecklistOwner() {
  const styleSide = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #cecece",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "100",
    fontSize: "30px",
    color: "#333333",
  };

  const styleSideInner = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "28px",
    color: "#333333",
  };

  //     -CHECK IN
  const [checkInState_Out, setCheckInState_Out] = useState(false);
  // -meet the pilot at the airport
  const [checkInState, setCheckInState] = useState(false);

  // -LOGBOOK REVIEW
  const [logBookReviewState_Out, setLogBookReviewState_Out] = useState(false);
  // -Bring aircraft maintenence logs for pilots to review One
  const [logBookReviewState_One, setLogBookReviewState_One] = useState(false);
  // -Review pilots logbooks, confirm aircraft experience Two
  const [logBookReviewState_Two, setLogBookReviewState_Two] = useState(false);

  // -PRE FLIGHT
  const [preFlight_Out, setPreFlight_Out] = useState(false);
  // -Watch pilot preflight aircraft One
  const [preFlight_One, setPreFlight_One] = useState(false);

  // -30 MIN SELF CHECKOUT
  const [thirty_Min_Out, setThirty_Min_Out] = useState(false);
  // -(link to checkout page) Link
  const [thirty_Min_Link, setThirty_Min_Link] = useState(false);

  // -FUEL
  const [fuel_Out, setFuel_Out] = useState(false);
  // -take picture or fuel level One
  const [fuel_One, setFuel_One] = useState(false);
  // (Aircraft must be returned with the level of fuel that the aircraft was received with)
  // Two
  const [fuel_Two, setFuel_Two] = useState(false);

  // -FLIGHT
  const [flight_Out, setFlight_Out] = useState(false);
  // -take picture and input hobbs time (before aircraft startup) One
  const [flight_One, setFlight_One] = useState(false);
  // -take picture and input hobbs time (after aircraft shutdown) Two
  const [flight_Two, setFlight_Two] = useState(false);

  return (
    <div>
      <Container style={{ padding: "5% 10%" }}>
        <h1> Hello from TripChecklist Owner </h1>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="CHECK IN
                "
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                Meet the Pilot at the airport (I met this person and authroize
                the use of my plane, cant stay boolean)
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={checkInState_Out}
              onChange={() => setCheckInState_Out(!checkInState_Out)}
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />

            <TabPanel trigger="CHECK IN T">
              <div style={styleSideInner}>
                <input
                  type="checkbox"
                  checked={checkInState}
                  onChange={() => setCheckInState(!checkInState)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>meet the pilot at the airport</p>
              </div>
            </TabPanel>
          </div> */}

          {/* 
                        @
                        @
                        @
                        @
                        @
                        @
                        */}

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="LOGBOOK REVIEW"
              />
            </AccordionSummary>
            <AccordionDetails>
              <TabPanel trigger="LOGBOOK REVIEW  T">
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={<Checkbox />}
                      label="Bring aircraft maintenence logs for pilots to review"
                    />
                  </li>
                  (I the owner brought reading and log books saying plane is up
                  to date and safe to fly. By checking you accept )
                  <li>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={<Checkbox />}
                      label="Review pilots logbooks, confirm aircraft experience"
                    />
                  </li>
                  (I the owner review the pilots reading and log books saying
                  pliot knows how to fly my aircraft and has experience. By
                  checking you accept )
                </ul>
                {/* <div style={styleSideInner}>
                  <input
                    type="checkbox"
                    checked={logBookReviewState_One}
                    onChange={() =>
                      setLogBookReviewState_One(!logBookReviewState_One)
                    }
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <p>Bring aircraft maintenence logs for pilots to review</p>
                </div>
                <div style={styleSideInner}>
                  <input
                    type="checkbox"
                    checked={logBookReviewState_Two}
                    onChange={() =>
                      setLogBookReviewState_Two(!logBookReviewState_Two)
                    }
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <p>Review pilots logbooks, confirm aircraft experience</p>
                </div> */}
              </TabPanel>
            </AccordionDetails>
          </Accordion>
          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={logBookReviewState_Out}
              onChange={() =>
                setLogBookReviewState_Out(!logBookReviewState_Out)
              }
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />

            <TabPanel trigger="LOGBOOK REVIEW  T">
              <div style={styleSideInner}>
                <input
                  type="checkbox"
                  checked={logBookReviewState_One}
                  onChange={() =>
                    setLogBookReviewState_One(!logBookReviewState_One)
                  }
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>Bring aircraft maintenence logs for pilots to review</p>
              </div>
              <div style={styleSideInner}>
                <input
                  type="checkbox"
                  checked={logBookReviewState_Two}
                  onChange={() =>
                    setLogBookReviewState_Two(!logBookReviewState_Two)
                  }
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>Review pilots logbooks, confirm aircraft experience</p>
              </div>
            </TabPanel>
          </div> */}
          {/* 
                        @
                        @
                        @
                        @
                        @
                        @
                        */}

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="PRE FLIGHT"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                Watch pilot preflight aircraft (by checking you the Owner are
                saying you saw visual in person pilot do his walk through)
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={preFlight_Out}
              onChange={() => setPreFlight_Out(!preFlight_Out)}
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />
            <TabPanel trigger="PRE FLIGHT T ">
              <div style={styleSideInner}>
                <input
                  type="checkbox"
                  checked={preFlight_One}
                  onChange={() => setPreFlight_One(!preFlight_One)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>-Watch pilot preflight aircraft</p>
              </div>
            </TabPanel>
          </div> */}

          {/* 
                        @
                        @
                        @
                        @
                        @
                        @
                        */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="30 MIN SELF CHECKOUT"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {" "}
                -(link to checkout page)
                <p>
                  SkyKandy Self- Checkout You have elected to complete the self
                  checkout process. The process will let you know whether the
                  pilots knowledge of the aircraft is effecient enough to rent
                  out your aircraft. The following checkout ,if completed, will
                  allow the pilot further access to your aircraft. (box that
                  says "checkout" that goes to the next page)
                </p>
                <ul>
                  <li> Use of check list</li>
                  <li>Normal takeoff</li>
                  <li>Power off or power on stall</li>
                  <li>Steep Turn</li>
                  <li>Simulated engine off procedures</li>
                  <li>Short field landing</li> <li>Go or No Go</li>
                  <li>
                    IF GO : would you the owner like to allow pilot skip review
                    on next flight?
                  </li>
                </ul>
                <p>
                  {" "}
                  By click owner says the where physically with the pilot in
                  flight
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={thirty_Min_Out}
              onChange={() => setThirty_Min_Out(!thirty_Min_Out)}
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />
            <TabPanel trigger="30 MIN SELF CHECKOUT T">
              <div style={styleSide}>
                <input
                  type="checkbox"
                  checked={thirty_Min_Link}
                  onChange={() => setThirty_Min_Link(!thirty_Min_Link)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>-(link to checkout page)</p>
              </div>
            </TabPanel>
          </div> */}

          {/* 
                        @
                        @
                        @
                        @
                        @
                        @
                        */}

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="FUEL"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {" "}
                -take picture or fuel level
              </Typography>
              <Typography>
                (Aircraft must be returned with the level of fuel that the
                aircraft was)
                <p>should drop image</p>
                <p>inupt starting level : 20 gl example</p>
                <p>cicrle back</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={fuel_Out}
              onChange={() => setFuel_Out(!fuel_Out)}
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />
            <TabPanel trigger="FUEL T">
              <div style={styleSide}>
                <input
                  type="checkbox"
                  checked={fuel_One}
                  onChange={() => setFuel_One(!fuel_One)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>-take picture or fuel level</p>
              </div>
              <div style={styleSide}>
                <input
                  type="checkbox"
                  checked={fuel_Two}
                  onChange={() => setFuel_Two(!fuel_Two)}
                  style={{
                    marginRight: "10px",
                  }}
                />

                <p>
                  (Aircraft must be returned with the level of fuel that the
                  aircraft was
                </p>
              </div>
            </TabPanel>
          </div> */}

          {/* 
                        @
                        @
                        @
                        @
                        @
                        @
                        */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="FLIGHT"
              />
            </AccordionSummary>
            <AccordionDetails>
              <TabPanel trigger="FLIGHT T">
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={<Checkbox />}
                      label="-take picture and input hobbs time (before aircraft startup)"
                    />
                  </li>
                  <li>
                    filled at the end. absoulte last thing
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={<Checkbox />}
                      label="-take picture and input hobbs time (after aircraft shutdown)"
                    />
                  </li>
                </ul>
                {/* <div style={styleSide}>
                  <input
                    type="checkbox"
                    checked={flight_One}
                    onChange={() => setFlight_One(!flight_One)}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <p>
                    -take picture and input hobbs time (before aircraft startup)
                  </p>
                </div>
                <div style={styleSide}>
                  <input
                    type="checkbox"
                    checked={flight_Two}
                    onChange={() => setFlight_Two(!flight_Two)}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <p>
                    -take picture and input hobbs time (after aircraft shutdown)
                  </p>
                </div> */}
              </TabPanel>
            </AccordionDetails>
          </Accordion>
          {/* <div style={styleSide}>
            <input
              type="checkbox"
              checked={flight_Out}
              onChange={() => setFlight_Out(!flight_Out)}
              style={{
                marginRight: "10px",
                fontSize: "30px",
                position: "relative",
              }}
            />
            <TabPanel trigger="FLIGHT T">
              <div style={styleSide}>
                <input
                  type="checkbox"
                  checked={flight_One}
                  onChange={() => setFlight_One(!flight_One)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>
                  -take picture and input hobbs time (before aircraft startup)
                </p>
              </div>
              <div style={styleSide}>
                <input
                  type="checkbox"
                  checked={flight_Two}
                  onChange={() => setFlight_Two(!flight_Two)}
                  style={{
                    marginRight: "10px",
                  }}
                />
                <p>
                  -take picture and input hobbs time (after aircraft shutdown)
                </p>
              </div>
            </TabPanel>
          </div> */}
        </div>
      </Container>
    </div>
  );
}

export default TripChecklistOwner;
