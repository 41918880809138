import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
// import Login from "./Login";
// import SignUp from "./SignUp";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./Auth";
import Header from "./Headers/Header";
import Footer from "./Footer/Footer";

import Profile from "../Profile/Profile";
import Trips from "../Trips/Trips";
import Messages from "../Messages/Messages";
import ListPlane from "../ListPlane/ListPlane";
import PlaneSearch from "../Plane/PlaneSearch";
import PlaneProfile from "../Plane/PlaneProfile";

import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

import HeaderLoggedOut from "./Headers/HeaderLoggedOut";
import TripContract from "../Trips/TripContract/TripContract";
import EditPlane from "../ListPlane/EditPlane/EditPlane";
import PilotSignUp from "../Profile/PilotSignUp/PilotSignUp";

import "./Main.css";
// fucking get rid of this

const Main = () => {
  return (
    <AuthProvider>
      {/* {(value) => console.log("value => ", value)} */}
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />

            <PrivateRoute exact path="/trips" component={Trips} />
            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute exact path="/list-your-plane" component={ListPlane} />

            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute
              exact
              path="/trip-contract/:id"
              component={TripContract}
            />
            <PrivateRoute exact path="/edit-plane/:id" component={EditPlane} />
            {/* fix later probably  */}
            <PrivateRoute
              exact
              path="/search/:address?"
              component={PlaneSearch}
            />
            {/* <PrivateRoute  exact path="/PlaneProfile/:id">
              <PlaneProfile {...plane} />
            </PrivateRoute> */}
            <PrivateRoute
              exact
              path="/PlaneProfile/:id"
              component={PlaneProfile}
            />
            {/* /:id/:subid */}
            <PrivateRoute
              path="/pilotsignup/:id/:subid"
              component={PilotSignUp}
            />
          </Switch>

          <div className="">
            <br></br>
            <br></br>
            <Footer />
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default Main;
