// import logo from './logo.svg';
import "./App.css";
import Main from "./components/Main/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
