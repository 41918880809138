import { setDayOfYear } from "date-fns";
import { useEffect, useState } from "react";
import app from "../components/Main/base";
import { getDoc, getImageUrl } from "../components/Utils/Utils";

export const usePilotSignUpImages = (collectionPath, Id) => {
  // Pilot license
  const [images, setImages] = useState([]);
  const [imagePath, setImagepath] = useState([]);

  // Drivers license
  const [imagesD, setImagesD] = useState([]);
  const [imagePathD, setImagepathD] = useState([]);

  // Medical Certification
  const [imagesMedCert, setImagesMedCert] = useState([]);
  const [imagePathMedCert, setImagepathMedCert] = useState([]);

  // Renter Incurance policy_pdf
  const [imagesRenterPolicy, setImagesRenterPolicy] = useState([]);
  const [imagePathRenterPolicy, setImagepathRenterPolicy] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const db = app.firestore();
    const storage = app.storage();

    const getCollection = (collectionPath, Id) =>
      getDoc(db, collectionPath, Id);

    const getPilotSignUpImages = async (imagesList) => {
      try {
        let pathList = [];
        const images = imagesList.map(async ({ image_path, name }) => {
          pathList.push(image_path);
          const url = await getImageUrl(storage, image_path);
          return { url, name };
        });
        setImagepath(pathList);
        return Promise.all(images);
      } catch (error) {
        throw error;
      }
    };

    const getPilotSignUpImagesD = async (imagesList) => {
      try {
        let pathList = [];
        const imagesD = imagesList.map(async ({ image_path, name }) => {
          pathList.push(image_path);
          const url = await getImageUrl(storage, image_path);
          return { url, name };
        });
        setImagepathD(pathList);
        return Promise.all(imagesD);
      } catch (error) {
        throw error;
      }
    };

    const getPilotSignUpImagesMedCert = async (imagesList) => {
      try {
        let pathList = [];
        const imagesMedCert = imagesList.map(async ({ image_path, name }) => {
          pathList.push(image_path);
          const url = await getImageUrl(storage, image_path);
          return { url, name };
        });
        setImagepathMedCert(pathList);
        return Promise.all(imagesMedCert);
      } catch (error) {
        throw error;
      }
    };

    const getPilotSignUpImagesRenterPolicy = async (imagesList) => {
      try {
        let pathList = [];
        const imagesRenterPolicy = imagesList.map(
          async ({ image_path, name }) => {
            pathList.push(image_path);
            const url = await getImageUrl(storage, image_path);
            return { url, name };
          }
        );
        setImagepathRenterPolicy(pathList);
        return Promise.all(imagesRenterPolicy);
      } catch (error) {
        throw error;
      }
    };

    try {
      const loadImages = async () => {
        const {
          drivers_license_images,
          pilots_license_images,
          medical_certification_images,
          renters_insurance,
        } = await getCollection(collectionPath, Id);
        const [imagesPilot, imagesDriver, imagesMed, imagesRenterPolicy] =
          await Promise.all([
            getPilotSignUpImages(pilots_license_images),
            getPilotSignUpImagesD(drivers_license_images),
            getPilotSignUpImagesMedCert(medical_certification_images),
            getPilotSignUpImagesRenterPolicy(renters_insurance.policy_pdf),
          ]);

        setImages(imagesPilot);
        setImagesD(imagesDriver);
        setImagesMedCert(imagesMed);
        setImagesRenterPolicy(imagesRenterPolicy);
      };

      loadImages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [app, collectionPath, Id]);

  return {
    images,
    imagePath,
    imagesD,
    imagePathD,
    imagesMedCert,
    imagePathMedCert,
    imagesRenterPolicy,
    imagePathRenterPolicy,
    isLoading,
  };
};
