import {
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Select } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { RadioGroup } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { useParams } from "react-router";
import app from "../../Main/base";
// import Timestamp from "../../Main/base";

// import Button from "react-bootstrap/Button";
// import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { get, useForm } from "react-hook-form";
import {
  Badge,
  Button,
  Col,
  CloseButton,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import * as util from "../../Utils/Utils";
import CarouselComponent from "../../Utils/Carousels/CarouselComponent";
import { usePilotSignUpImages } from "../../../hooks/usePilotSignUpImages";

import "./PilotSignUp.scss";
import { set, setDate } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    //fontSize: theme.typography.pxToRem(15),
    //color: theme.palette.text.secondary,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  formControl: {
    width: "100%",
  },
  dropZone: {
    height: "10rem",
    display: "flex",
  },
}));

function MyDropzone() {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();
  return (
    <Paper className={classes.dropZone}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
    </Paper>
  );
}

const PilotSignUp = (props) => {
  const { id, subid } = useParams();
  const db = app.firestore();

  const [medicalDateError, setMedicalDateError] = useState("");
  const [policyDateError, setPolicyDateError] = useState("");
  const [flightReviewError, setFlightReviewError] = useState("");

  const [pilotPictureError, setPilotPictureError] = useState("");
  const [driversPictureError, setDriversPictureError] = useState("");
  const [medicalPictureError, setMedicalPictureError] = useState("");
  const [rentersPictureError, setRentersPictureError] = useState("");

  const collection = `chatUsers/${id}/pilot_info`;

  // Modals
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  ///
  const [showS, setShowS] = useState(false);

  const handleCloseS = () => setShowS(false);
  const handleShowS = () => setShowS(true);
  // Modals End

  const [pilotInfo, setPilotInfo] = useState({
    certificates: {
      airline_transport: "",
      commercial: "",
      flight_instructor: "",
      private_pilot: "",
      sport_pilot: "",
    },
    drivers_license_images: [
      {
        image_path: `/users/${id}/drivers_license_images_front`,
        name: `front D`,
      },
      {
        image_path: `/users/${id}/drivers_license_images_back`,
        name: `back D`,
      },
    ],

    medical_certification_images: [
      {
        image_path: `/users/${id}/medical_certification_images`,
        name: `Medical Certification`,
      },
    ],
    medical_class: ``,
    medical_class_exp_date: new Date(),
    medical_class_issue_date: new Date(),
    pilots_license_images: [
      {
        image_path: `/users/${id}/pilots_license_images_front`,
        name: `front`,
      },
      {
        image_path: `/users/${id}/pilots_license_images_back`,
        name: `back`,
      },
    ],
    rating_endorsement: {
      complex_airplane_endorsment: ``,
      glider_endorsement: ``,
      high_altitude_endorsement: ``,
      high_performance_endorsement: ``,
      instrument_rating: ``,
      multi_engine_rating: ``,
      tail_wheel_endorsement: ``,
    },
    renters_insurance: {
      insurance_name: ``,
      policy_expiration_date: new Date(),
      policy_insured_date: new Date(),
      policy_number: ``,
      policy_pdf: [
        {
          image_path: `/users/${id}/renters_insurance_policy_pdf`,
          name: `Renters Insurance Policy pdf`,
        },
      ],
    },
    pilot_info_complete: false,
    flight_review_date: new Date(),
  });

  const pilotInfoCompleteCheck = () => {
    console.log("pilotInfoCompleteCheck");
    let toReturn = false;
    !images[0].url || !images[1].url
      ? setPilotPictureError("Image is missing")
      : setPilotPictureError("");
    !imagesD[0].url || !imagesD[1].url
      ? setDriversPictureError("Image is missing")
      : setDriversPictureError("");
    !imagesMedCert[0].url
      ? setMedicalPictureError("Image is missing")
      : setMedicalPictureError("");

    !imagesRenterPolicy[0].url
      ? setRentersPictureError("Image is missing")
      : setRentersPictureError("");

    if (
      imagesMedCert[0].url &&
      images[0].url &&
      images[1].url &&
      imagesD[0].url &&
      imagesD[1].url &&
      imagesRenterPolicy[0].url
    ) {
      // setValue("pilot_info_complete", true);
      toReturn = true;
    }

    console.log(
      imagesMedCert[0].url,
      images[0].url,
      images[1].url,
      imagesD[0].url,
      imagesD[1].url,
      imagesRenterPolicy[0].url
    );
    console.log("pilotInfoCompleteCheck Fin toReturn => ", toReturn);
    return toReturn;
  };

  const {
    handleSubmit,
    watch,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: pilotInfo,
  });

  // Medical Issue Date
  const [medicalIssueDate, setMedicalIssueDate] = useState(null);
  const handleMedicalIssueDateChange = (date) => {
    if (!date) {
      setMedicalDateError("Can not be empty");
      return null;
    }

    date.getTime() > medicalExpirationDate.getTime()
      ? setMedicalDateError("medical issue date is not valid")
      : setMedicalDateError("");
    setValue("medical_class_issue_date", date);
    setMedicalIssueDate(date);
  };

  // Medical Expiration Date
  const [medicalExpirationDate, setMedicalExpirationDate] = useState(null);
  const handleExpirationDateChange = (date) => {
    if (!date) {
      setMedicalDateError("Can not be empty");
      return null;
    }
    date.getTime() < medicalIssueDate.getTime()
      ? setMedicalDateError("expiration date is not valid")
      : setMedicalDateError("");
    setValue("medical_class_exp_date", date);
    setMedicalExpirationDate(date);
  };

  // Policy Insured Date
  const [policyInsuredDate, setPolicyInsuredDate] = useState(null);
  const handlePolicyInsuredDateChange = (date) => {
    if (!date) {
      setPolicyDateError("Can not be empty");
      return null;
    }
    date.getTime() > policyExpirationDate.getTime()
      ? setPolicyDateError("policy insured date is not valid")
      : setPolicyDateError("");
    setValue("policy_insured_date", date);
    setPolicyInsuredDate(date);
  };

  // Policy Expiration Date
  const [policyExpirationDate, setPolicyExpirationDate] = useState(null);
  const handlePolicyExpirationDateChange = (date) => {
    if (!date) {
      setPolicyDateError("Can not be empty");
      return null;
    }
    date.getTime() < policyInsuredDate.getTime()
      ? setPolicyDateError("policy expiration date is not valid")
      : setPolicyDateError("");
    setValue("policy_expiration_date", date);
    setPolicyExpirationDate(date);
  };

  // Last Bi-Annual flight review
  const [flightReviewDate, setFlightReviewDate] = useState(null);
  const handleFlightReviewDateChange = (date) => {
    if (!date) {
      setFlightReviewError("Can not be empty");
      return null;
    }
    setValue("flight_review_date", date);
    setFlightReviewDate(date);
  };

  const {
    images,
    imagePath,
    imagesD,
    imagePathD,
    imagesMedCert,
    imagePathMedCert,
    imagesRenterPolicy,
    imagePathRenterPolicy,
  } = usePilotSignUpImages(collection, subid);

  const onSubmit = async (data) => {
    // only submit if something is changed in form
    if (isDirty) {
      //console.log("changed");
      // checkdates
      if (
        medicalIssueDate != null &&
        medicalExpirationDate != null &&
        policyInsuredDate != null &&
        policyExpirationDate != null &&
        flightReviewDate != null &&
        medicalDateError == "" &&
        policyDateError == "" &&
        flightReviewError == ""
      ) {
        if (pilotInfoCompleteCheck()) {
          setValue("pilot_info_complete", true);
          handleShowS();
        } else {
          setValue("pilot_info_complete", false);
          handleShow();
        }
        await util.updateDoc2(
          db,
          `chatUsers/${id}/pilot_info`,
          subid,
          getValues()
        );
      }
    }
  };
  const getPilotInfo = async (data) => {
    // console.log("get => ", getValues());
  };

  const [loading, setLoading] = useState(true);

  const getPliotInfo = async () => {
    let doc = await util.getDoc(app.firestore(), collection, subid);
    setPilotInfo(doc);
  };

  useEffect(() => {
    getPliotInfo().then(() => {
      console.log("Pilot Info,", pilotInfo);
      //console.log(pilotInfo.medical_class_issue_date);
      //setMedicalIssueDate(pilotInfo.medical_class_issue_date);

      //console.log(util.toDateTime(pilotInfo.medical_class_issue_date.seconds));
      // console.log("AAAAAA ", pilotInfo.renters_insurance.policy_insured_date);
      setMedicalIssueDate(
        util.toDateTime(pilotInfo.medical_class_issue_date.seconds)
      );
      setMedicalExpirationDate(
        util.toDateTime(pilotInfo.medical_class_exp_date.seconds)
      );
      setPolicyInsuredDate(
        util.toDateTime(pilotInfo.renters_insurance.policy_insured_date.seconds)
      );
      setPolicyExpirationDate(
        util.toDateTime(
          pilotInfo.renters_insurance.policy_expiration_date.seconds
        )
      );
      setFlightReviewDate(
        util.toDateTime(pilotInfo.flight_review_date.seconds)
      );
      setLoading(false);
    });
  }, [loading]); //  Run once on load
  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(pilotInfo);
  }, [pilotInfo]);
  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //
  if (loading) {
    return <h1>loading firebase data...</h1>;
  }
  return (
    <Container>
      {/* <h1> Needs some fixing/completeing </h1> */}
      <List>
        <ListItemText>
          <Typography variant="h4" color="primary">
            Pilot License
          </Typography>
        </ListItemText>
        <ListItemText>
          <Typography>Upload Pilots License Front</Typography>
        </ListItemText>
        {/* <CarouselComponent />  get the genric one and add to database*/}
        {/* <ListItem> */}
        <CarouselComponent images={images} imagePath={imagePath} />
        {pilotPictureError && (
          <FormHelperText className="carousel-error error-text">
            {pilotPictureError}
          </FormHelperText>
        )}
        <ListItemText>
          <Typography variant="h4" color="primary">
            Drivers License
          </Typography>
        </ListItemText>
        {/* <ListItem> */}
        <CarouselComponent images={imagesD} imagePath={imagePathD} />
        {driversPictureError && (
          <FormHelperText className="carousel-error error-text">
            {driversPictureError}
          </FormHelperText>
        )}
        <ListItemText>
          <Typography variant="h4" color="primary">
            Medical Certification
          </Typography>
        </ListItemText>

        <ListItemText>
          <Typography>Upload Medical Certification</Typography>
        </ListItemText>
        <CarouselComponent
          images={imagesMedCert}
          imagePath={imagePathMedCert}
        />
        {medicalPictureError && (
          <FormHelperText className="carousel-error error-text">
            {medicalPictureError}
          </FormHelperText>
        )}

        <ListItemText>
          <Typography variant="h4" color="primary">
            Medical Class
          </Typography>
        </ListItemText>

        <Form onSubmit={handleSubmit(onSubmit)} className="px-3 pb-5">
          <Form.Group as={Col} controlId="formGridState" className="mb-3">
            <Form.Label labelid="medicalClass">Medical Class</Form.Label>
            <Form.Select
              defaultValue="Choose..."
              isInvalid={!!errors.medical_class}
              {...register("medical_class", {
                required: "Medical Class is required",
              })}
            >
              <option value="" label=""></option>
              <option value="A" label="Class A">
                Class A
              </option>
              <option value="B" label="Class B">
                Class B
              </option>
              <option value="C" label="Class C">
                Class C
              </option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.medical_class?.message}
            </Form.Text>
          </Form.Group>
          <Row className="mb-1">
            <Form.Group as={Col} xs={12} sm={6} md={3}>
              <Form.Label>Medical Issue Date</Form.Label>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  format="MM/dd/yyyy"
                  value={medicalIssueDate}
                  onChange={handleMedicalIssueDateChange}
                  // invalidDateMessage
                  // value={watch("medical_class_issue_date")}
                  // onChange={(value) => {
                  //   setValue("medical_class_issue_date", value);
                  // }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <ListItemText>Medical Expiration Date</ListItemText>
            <ListItem>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  format="MM/dd/yyyy"
                  value={medicalExpirationDate}
                  onChange={handleExpirationDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </ListItem>
            {medicalDateError && (
              <FormHelperText>{medicalDateError}</FormHelperText>
            )}
          </Row>

          <ListItemText>
            <Typography variant="h4" color="primary">
              Renters Insurance
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography variant="h5" color="primary">
              Renters Insurance (must include a minimum coverage of $250,000
              liability and 20,000 in aircraft damage)
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography>
              Flying rented aircraft? Avemco Insurance Company has coverages
              designed to protect you. Click on the Avemco​®​ link to learn
              more, or to buy online.
            </Typography>
          </ListItemText>
          <ListItemText>
            <Typography>
              Click Here:{" "}
              <a href="https://avemco.com/SK01">https://avemco.com/SK01</a>
            </Typography>
          </ListItemText>
          {/*  ======================================================================================= */}
          <Form.Group>
            <Form.Label>Policy Number</Form.Label>
            <Form.Control
              className="mb-3"
              isInvalid={!!errors.renters_insurance?.policy_number}
              {...register("renters_insurance.policy_number", {
                required: "Policy Number is required",
              })}
            />
            <Form.Text className="text-danger">
              {errors.renters_insurance?.policy_number?.message}
            </Form.Text>
            {/* policy_number */}
          </Form.Group>

          <Form.Group>
            <Form.Label>Insurance Name</Form.Label>
            <Form.Control
              className="mb-3"
              isInvalid={!!errors.renters_insurance?.insurance_name}
              {...register("renters_insurance.insurance_name", {
                required: "Policy Name is required",
              })}
            />
            <Form.Text className="text-danger">
              {errors.renters_insurance?.insurance_name?.message}
            </Form.Text>
          </Form.Group>

          <Row className="mb-1">
            <Form.Group as={Col} xs={12} sm={6} md={3}>
              <Form.Label>Policy Insured Date</Form.Label>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="mb-3">
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  format="MM/dd/yyyy"
                  //label="policy_insured_date"
                  value={policyInsuredDate}
                  onChange={handlePolicyInsuredDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <Form.Label>Policy Expiration Date</Form.Label>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="mb-3">
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  format="MM/dd/yyyy"
                  //label="policy_expiration_date"
                  value={policyExpirationDate}
                  onChange={handlePolicyExpirationDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {policyDateError && (
                <FormHelperText>{policyDateError}</FormHelperText>
              )}
            </Form.Group>
            {policyDateError && (
              <Form.Text className="error-text">{policyDateError}</Form.Text>
            )}
          </Row>

          {/* Change to PDF */}
          <CarouselComponent
            images={imagesRenterPolicy}
            imagePath={imagePathRenterPolicy}
            pdf={false}
          />
          {rentersPictureError && (
            <FormHelperText className="carousel-error error-text">
              {rentersPictureError}
            </FormHelperText>
          )}

          {/*  ======================================================================================= */}
          <ListItemText>
            <Typography variant="h4" color="primary">
              Bi-Annual Flight Review
            </Typography>
          </ListItemText>
          <Form.Group as={Col} xs={12} sm={6} md={3}>
            <Form.Label>Last Bi-Annual Flight Review</Form.Label>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="start-date"
                format="MM/dd/yyyy"
                // label="Last Bi-Annual flight review"
                value={flightReviewDate}
                onChange={handleFlightReviewDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Form.Group>
          {flightReviewError && (
            <Form.Text className="error-text">{flightReviewError}</Form.Text>
          )}

          <ListItemText>
            <Typography variant="h4" color="primary">
              Certificates
            </Typography>
          </ListItemText>
          <Form.Group as={Row} className="mb-3">
            <Row>
              <Col>
                <ListItemText>Sport Pilot</ListItemText>
                <Form.Select
                  aria-label="sport pilot"
                  isInvalid={!!errors.certificates?.sport_pilot}
                  {...register("certificates.sport_pilot", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.certificates?.sport_pilot?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>Airline Transport</ListItemText>
                <Form.Select
                  aria-label="airline_transport"
                  isInvalid={!!errors.certificates?.airline_transport}
                  {...register("certificates.airline_transport", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.certificates?.airline_transport?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>Commercial</ListItemText>
                <Form.Select
                  aria-label="commercial"
                  isInvalid={!!errors.certificates?.commercial}
                  {...register("certificates.commercial", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.certificates?.commercial?.message}
                </Form.Text>
                {/*  */}
              </Col>
              <Col>
                <ListItemText>Flight Instructor</ListItemText>
                <Form.Select
                  aria-label="flight_instructor"
                  isInvalid={!!errors.certificates?.flight_instructor}
                  {...register("certificates.flight_instructor", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.certificates?.flight_instructor?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>Private Pilot</ListItemText>
                <Form.Select
                  aria-label="private_pilot"
                  isInvalid={!!errors.certificates?.private_pilot}
                  {...register("certificates.private_pilot", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.certificates?.private_pilot?.message}
                </Form.Text>
                {/*  */}
              </Col>
            </Row>
          </Form.Group>
          {/*  */}
          <ListItemText className="title-text ">
            <Typography variant="h4" color="primary">
              Rating and Endorsement
            </Typography>
          </ListItemText>
          <Form.Group>
            <Row>
              <Col>
                <ListItemText>Complex Airplane Endorsment</ListItemText>
                <Form.Select
                  aria-label="complex_airplane_endorsment"
                  isInvalid={
                    !!errors.rating_endorsement?.complex_airplane_endorsment
                  }
                  {...register(
                    "rating_endorsement.complex_airplane_endorsment",
                    {
                      required: "Feild is required",
                    }
                  )}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {
                    errors.rating_endorsement?.complex_airplane_endorsment
                      ?.message
                  }
                </Form.Text>
                {/*  */}
                <ListItemText>Glider Endorsement</ListItemText>
                <Form.Select
                  aria-label="glider_endorsement"
                  isInvalid={!!errors.rating_endorsement?.glider_endorsement}
                  {...register("rating_endorsement.glider_endorsement", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.rating_endorsement?.glider_endorsement?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>High Altitude Endorsement</ListItemText>
                <Form.Select
                  aria-label="high_altitude_endorsement"
                  isInvalid={
                    !!errors.rating_endorsement?.high_altitude_endorsement
                  }
                  {...register("rating_endorsement.high_altitude_endorsement", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {
                    errors.rating_endorsement?.high_altitude_endorsement
                      ?.message
                  }
                </Form.Text>
                {/*  */}
                <ListItemText>High Performance Endorsement</ListItemText>
                <Form.Select
                  aria-label="high_performance_endorsement"
                  isInvalid={
                    !!errors.rating_endorsement?.high_performance_endorsement
                  }
                  {...register(
                    "rating_endorsement.high_performance_endorsement",
                    {
                      required: "Feild is required",
                    }
                  )}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {
                    errors.rating_endorsement?.high_performance_endorsement
                      ?.message
                  }
                </Form.Text>
                {/*  */}
              </Col>
              <Col>
                <ListItemText>Instrument Rating</ListItemText>
                <Form.Select
                  aria-label="instrument_rating"
                  isInvalid={!!errors.rating_endorsement?.instrument_rating}
                  {...register("rating_endorsement.instrument_rating", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.rating_endorsement?.instrument_rating?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>Multi Engine Rating</ListItemText>
                <Form.Select
                  aria-label="multi_engine_rating"
                  isInvalid={!!errors.rating_endorsement?.multi_engine_rating}
                  {...register("rating_endorsement.multi_engine_rating", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.rating_endorsement?.multi_engine_rating?.message}
                </Form.Text>
                {/*  */}
                <ListItemText>Tail Wheel Endorsement</ListItemText>
                <Form.Select
                  aria-label="tail_wheel_endorsement"
                  isInvalid={
                    !!errors.rating_endorsement?.tail_wheel_endorsement
                  }
                  {...register("rating_endorsement.tail_wheel_endorsement", {
                    required: "Feild is required",
                  })}
                >
                  <option value={""}></option>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.rating_endorsement?.tail_wheel_endorsement?.message}
                </Form.Text>
                {/*  */}
              </Col>
            </Row>
          </Form.Group>
          <br />
          <br />
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                Saving...
                <Spinner
                  style={{ marginLeft: 8 }}
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              "Done"
            )}
          </Button>
        </Form>
      </List>
      <br />
      <br />
      <br />
      {/* Modals */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Not Complete, But Saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your work has been saved but your pilot sign up is NOT complete.
          Please make sure your dates are in order, all images have been
          uploaded before sumbiting and that all fields have been filled out.
          Thank you and try again
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal S */}
      <Modal show={showS} onHide={handleCloseS}>
        <Modal.Header closeButton>
          <Modal.Title>Completed!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Congrats, you have completed the pilot sign up and are now ready to
          rent out planes. Search and browse through the listing for the planes
          right for you. Respect the Air, planes and owners. Safety is our top
          priority and we hope for smooth journeys.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseS}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PilotSignUp;
