import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import DATA_IMAGE from "./defualtCarouselImage.json";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

// import PlaneDropzone from "../../../../Utils/Dropzones/PlaneDropzone";
import PlaneDropzone from "../Dropzones/PlaneDropzone";
import GenericDropzone from "../Dropzones/GenericDropzone";
// import app from "../../../../Main/base";
import app from "../../Main/base";
// import { getImageUrl } from "../../../../Utils/Utils";
import { getImageUrl } from "../Utils";
import ProgressBar from "react-bootstrap/ProgressBar";
import CarouselLayout from "./CarouselLayout";
import PDFview from "../PDF/PDFview";
import pdfDoc from "../../../images/dummy.pdf";

export const CarouselComponent = ({ images, id, imagePath, pdf }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [plane, setPlane] = useState({});
  const [displayImages, setDisplayImages] = useState([]);

  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(0);

  useEffect(() => {}, []); //  Run once on load

  return (
    <div>
      <CarouselLayout images={images} />
      {/* <ProgressBar variant="success" now={progress} className="mt-3" /> */}
      <Button
        className="mt-3 w-100"
        onClick={handleShow}
        variant="primary"
        size="lg"
      >
        Edit Photos
      </Button>

      <Offcanvas
        scroll={true}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Picture</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {imagePath?.map((image, index) => (
            <div>
              {pdf ? (
                <PDFview document={pdfDoc} />
              ) : (
                <>
                  {" "}
                  <GenericDropzone imagePath={image} />
                  <br /> <br /> <br />
                  <br /> <br /> <br />
                  <br /> <br /> <br />
                  <br /> <br /> <br />
                </>
              )}
            </div>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CarouselComponent;
