import React, { useState } from "react";
import app from "../Main/base";
import { useEffect } from "react";
// import { useChat } from 'context';
import { getChats, ChatEngine, getOrCreateChat } from "react-chat-engine";

// https://reactjs.org/docs/components-and-props.html
// use for passing props

const Messages = (props) => {
  const [username, setUsername] = useState("");

  function createDirectChat(creds) {
    getOrCreateChat(
      creds,
      { is_direct_chat: true, usernames: [username] },
      () => setUsername("")
    );
  }

  function renderChatForm(creds) {
    return (
      <div>
        <input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button onClick={() => createDirectChat(creds)}>Create</button>
      </div>
    );
  }

  return (
    // RMOVE this info
    <ChatEngine
      height="90vh"
      projectID="3f031505-87e3-4205-afb1-8d9043db5d86"
      userName="user_02"
      //   userSecret will be user ID
      userSecret="password"
      renderNewChatForm={(creds) => renderChatForm(creds)}
    />
  );
};

export default Messages;
