const style = {
  banner: {
    minWidth: "100vw",
    height: "50vh",
    position: "relative",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    "object-fit": "cover",
    overflow: "hidden",
  },
  overlay: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 255, 255,.15)",
    color: "white",
  },
};

const Banner = (props) => {
  const { children, imageUrl } = props;
  return (
    <div className="Banner" style={style.banner}>
      <img src={imageUrl} style={style.backgroundImage} />
      <div style={style.overlay}>{children}</div>
    </div>
  );
};

export default Banner;
