import React, { useEffect, useState, Component } from "react";
import { useForm } from "react-hook-form";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarComponent.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
//import TimePicker from "react-time-picker";
import {
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core";

import Spinner from "react-bootstrap/Spinner";

import app from "../../Main/base";
import StaticTime from "../../Models/StaticTime";
import RecurringTime from "../../Models/RecurringTime";
import {
  getDoc,
  getImageUrl,
  updateDoc,
  updateDoc2,
  toDateTime,
  reload,
  createStaticTimeList,
  createRecurringTimesList,
} from "../Utils";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import WeekDayComponent from "./WeekDayComponent";
import AddEventComponent from "./AddEventComponent";
import UpdateEventComponent from "./UpdateEventComponent";
import UpdateRecEventComponent from "./UpdateRecEventComponent";
import AddRecEventComponent from "./AddRecEventComponent";
import { te } from "date-fns/locale";

// ---------- Tabs ----------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStylesTab = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    transition: "position 500ms ease",
  },
}));

// Calendar
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Big Meeting",
    allDay: true,
    start: new Date(2022, 6, 0),
    end: new Date(2022, 6, 0),
  },
  {
    title: "Vacation",
    start: new Date(2022, 6, 7),
    end: new Date(2022, 6, 10),
  },
  {
    title: "Conference",
    start: new Date(2022, 6, 20),
    end: new Date(2022, 6, 23),
  },
  {
    title: "Conference 2",
    start: new Date(2022, 5, 15),
    end: new Date(2022, 5, 22),
    allDay: false,
  },
  {
    title: "available",
    start: new Date(2022, 5, 15),
    end: new Date(2022, 5, 15),
    allDay: false,
  },
  {
    id: 6,
    title: "Meeting",
    start: new Date(2022, 5, 12, 10, 30, 0, 0),
    end: new Date(2022, 5, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 9,
    title: "Happy Hour",
    start: new Date(2022, 5, 12, 17, 0, 0, 0),
    end: new Date(2022, 5, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
];

function CalendarComponent({ plane_id }) {
  const [newEvent, setNewEvent] = useState({
    title: "Available",
    start: "",
    end: "",
  });
  const db = app.firestore();
  const storage = app.storage();
  const [disable, setDisable] = useState(true);
  const [disableRec, setDisableRec] = useState(true);

  //
  //
  //  ================= ======== Current Selected Times Start ========= =================

  const [staticTime, setStaticTime] = useState(new StaticTime());
  const [recurringTime, setRecurringTime] = useState(new StaticTime());
  //  ================= ======== Current Selected Times End ========= =================
  //
  //

  const [allEvents, setAllEvents] = useState(events);

  //  ================= ======== Handle Events Start ========= =================
  async function handleAddCEvent(data) {
    alert("Oh boy, data =>" + " - " + JSON.stringify(data)); // <======== Here
    let planeObj = plane;
    planeObj.static_time.push(data);
    console.log("new planeObj => ", planeObj);
    setPlane(planeObj);
    // setAllEvent as well
    //setAllEvents(planeObj.static_time);
    //setAllEvents(null);
    await setTimeEvents(planeObj);
    await updateDoc2(db, "plane", plane_id, planeObj);

    let addTime = new StaticTime(
      data.time_id,
      data.start,
      data.end,
      data.flat_rate,
      data.rate_per_hour,
      data.pilot_id,
      data.title,
      data.color
    );

    //setTheArray(prevArray => [...prevArray, newValue])
    setAllEvents((allEvents) => [...allEvents, addTime]);
  }

  async function handleAddRecurringEvent(data, day) {
    alert("Oh boy, Recurring data =>" + " - " + JSON.stringify(data)); // <======== Here
    let planeObj = plane;

    // const dayIndex = planeObj.recurring_time.findIndex(
    //   (x) => x.title === data.title
    // );

    switch (day) {
      case "SUN":
        // day = "Sunday";
        planeObj.recurring_time[0].times.push(data);
        break;
      case "MON":
        // day = "Monday";
        planeObj.recurring_time[1].times.push(data);
        break;
      case "TUE":
        // day = "Tuesday";
        planeObj.recurring_time[2].times.push(data);
        break;
      case "WED":
        // day = "Wednesday";
        planeObj.recurring_time[3].times.push(data);
        break;
      case "THU":
        // day = "Thursday";
        planeObj.recurring_time[4].times.push(data);
        break;
      case "FRI":
        // day = "Friday";
        planeObj.recurring_time[5].times.push(data);
        break;
      case "SAT":
        // day = "Saturday";
        planeObj.recurring_time[6].times.push(data);
    }

    // let recuringTime = data;
    // planeObj.recurring_time[dayIndex].times.push(recuringTime);

    //planeObj.recurring_time[index] = data;
    console.log("222 Recurring new planeObj => ", planeObj.recurring_time);
    setPlane(planeObj);
    //setAllEvents(recurringTimesList);
    // setAllEvent as well
    // setAllEvents(planeObj.static_time);
    //await setTimeEvents(planeObj);
    await updateDoc2(db, "plane", plane_id, planeObj);

    let recurringTimeList = await createRecurringTimesList(planeObj, 1);
    console.table(recurringTimeList);
    console.log("recurringTimeList => ", recurringTimeList);
  }

  async function handleDeleteEvent() {
    let planeObj = plane;
    const deleteIndex = plane.static_time.findIndex(
      (x) => x.time_id === staticTime.time_id
    );
    let allEventsIndex = allEvents.findIndex(
      (x) => x.time_id === staticTime.time_id
    );
    if (deleteIndex !== -1) {
      planeObj.static_time.splice(deleteIndex, 1);
      setPlane(planeObj);
      // let timeList = [];
      // timeList = createStaticTimeList(planeObj);
      // timeList = createRecurringTimesList(planeObj, 2).concat(timeList);
      // setAllEvents(timeList);
      // await setTimeEvents(planeObj); ++++++++++++++++
      await updateDoc2(db, "plane", plane_id, planeObj);

      let tempEvents = [...allEvents];
      //tempEvents[allEventsIndex] = updateTime;
      tempEvents.splice(allEventsIndex, 1);
      setAllEvents(tempEvents);
    }
  }

  async function handleDeleteRecEvent(data) {
    alert("Delete, data =>" + " - " + JSON.stringify(data)); // <======== Here
    let planeObj = plane;
    let dayIndex = -1;

    switch (data.title) {
      case "SUN":
        dayIndex = 0;
        break;
      case "MON":
        dayIndex = 1;
        break;
      case "TUE":
        dayIndex = 2;
        break;
      case "WED":
        dayIndex = 3;
        break;
      case "THU":
        dayIndex = 4;
        break;
      case "FRI":
        dayIndex = 5;
        break;
      case "SAT":
        dayIndex = 6;
        break;
    }

    const deleteIndex = planeObj.recurring_time[dayIndex].times.findIndex(
      (x) => x.time_id === recurringTime.time_id
    );
    if (deleteIndex !== -1) {
      planeObj.recurring_time[dayIndex].times.splice(deleteIndex, 1);
      setPlane(planeObj);

      await setTimeEvents(planeObj);
      await updateDoc2(db, "plane", plane_id, plane);
    }
  }

  async function handleUpdateEvent(data) {
    alert("handleUpdateEvent =>" + " - " + JSON.stringify(data)); // <======== Here
    let pilotId = "";
    let planeObj = plane;

    let updateStaticTime = {
      time_id: data.time_id,
      end: data.end,
      flat_rate: data.flat_rate,
      pilot_id: "",
      rate_per_hour: data.rate_per_hour,
      start: data.start,
      title: data.title,
      color: "lightgreen",
    };

    const updateIndex = plane.static_time.findIndex(
      (x) => x.time_id === updateStaticTime.time_id
    );
    let allEventsIndex = allEvents.findIndex((x) => x.time_id === data.time_id);
    // console.log("updateIndex => ", updateIndex);
    if (updateIndex !== -1) {
      //arr[index].name = 'John';
      planeObj.static_time[updateIndex] = updateStaticTime;
      setPlane(planeObj);
      //setAllEvents(planeObj.static_time);
      console.log("planeObj.static_time => ", planeObj.static_time);
      console.log("plane => ", plane);
      //
      // let timeList = [];
      // timeList = createStaticTimeList(planeObj);
      // timeList = createRecurringTimesList(planeObj, 2).concat(timeList);
      // setAllEvents(timeList);
      await setTimeEvents(planeObj);
      //
      await updateDoc2(db, "plane", plane_id, planeObj);

      let updateTime = new StaticTime(
        data.time_id,
        data.start,
        data.end,
        data.flat_rate,
        data.rate_per_hour,
        data.pilot_id,
        data.title,
        data.color
      );

      //let newArr = [...data]; // copying the old datas array
      let tempEvents = [...allEvents];
      // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
      //newArr[index] = e.target.value; // replace e.target.value with whatever you want to change it to
      tempEvents[allEventsIndex] = updateTime;

      //setData(newArr);
      setAllEvents(tempEvents);

      //setTheArray(prevState => [...prevState, {currentOrNewKey: newValue}]);
      //setAllEvents((prevState) => [...prevState, { allEventsIndex: updateTime }]);
    }
    //planeObj.static_time.push(staticTime);
    console.log("update planeObj => ", planeObj.static_time);
  }

  async function handleUpdateRecEvent(data) {
    alert("Update, data =>" + " - " + JSON.stringify(data)); // <======== Here
    let planeObj = plane;
    let dayIndex = -1;

    switch (data.title) {
      case "SUN":
        dayIndex = 0;
        break;
      case "MON":
        dayIndex = 1;
        break;
      case "TUE":
        dayIndex = 2;
        break;
      case "WED":
        dayIndex = 3;
        break;
      case "THU":
        dayIndex = 4;
        break;
      case "FRI":
        dayIndex = 5;
        break;
      case "SAT":
        dayIndex = 6;
        break;
    }

    let updateStaticTime = {
      time_id: data.time_id,
      end: data.end,
      flat_rate: data.flat_rate,
      pilot_id: "",
      rate_per_hour: data.rate_per_hour,
      start: data.start,
      title: data.title,
      color: "lightblue",
      active: false,
    };

    const updateIndex = planeObj.recurring_time[dayIndex].times.findIndex(
      (x) => x.time_id === updateStaticTime.time_id
    );

    if (updateIndex !== -1) {
      planeObj.recurring_time[dayIndex].times[updateIndex] = updateStaticTime;
      setPlane(planeObj);
      console.log("planeObj.static_time => ", planeObj.recurring_time);
      console.log("plane => ", plane);
      //
      // let timeList = [];
      // timeList = createStaticTimeList(plane);
      // timeList = createRecurringTimesList(plane, 2).concat(timeList);
      // setAllEvents(timeList);
      await setTimeEvents(planeObj);
      //
      await updateDoc2(db, "plane", plane_id, plane);
    }
  }
  //
  //
  //
  //
  //
  //
  //

  const handleEventSelection = (e) => {
    if (e.color == "lightgreen") {
      setValue(0);
      handleLightGreen(e);
    } else if (e.color == "lightblue") {
      setValue(1);
      handleLightBlue(e);
    }
  };

  const handleLightGreen = (e) => {
    // console.log(e, "Event data");
    // console.log(e.flat_rate, "Event data");
    let index = plane.static_time.findIndex((x) => x.time_id === e.time_id);
    console.log(index, "Event data");

    // saving current Objects
    // setTimeId(e.time_id);
    setStaticTime(e);
    setDisable(false);
  };

  const handleLightBlue = (e) => {
    console.log(e, "Event data Blue");
    let index = "";

    switch (e.title) {
      case "SUN":
        // day = "Sunday";
        index = plane.recurring_time[0].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "MON":
        // day = "Monday";
        index = plane.recurring_time[1].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "TUE":
        // day = "Tuesday";
        index = plane.recurring_time[2].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "WED":
        // day = "Wednesday";
        index = plane.recurring_time[3].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "THU":
        // day = "Thursday";
        index = plane.recurring_time[4].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "FRI":
        // day = "Friday";
        index = plane.recurring_time[5].times.findIndex(
          (x) => x.time_id === e.time_id
        );
        break;
      case "SAT":
        // day = "Saturday";
        index = plane.recurring_time[6].times.findIndex(
          (x) => x.time_id === e.time_id
        );
    }
    console.log(index, "Event data");
    ///setTimeId(e.time_id);
    setRecurringTime(e);
    setDisableRec(false);
  };

  //  ================= ======== Handle Events END ========= =================
  // //
  // //
  // //

  const [show, setShow] = useState(false);

  const theme = useTheme();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [value, setValue] = React.useState(0);
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [plane, setPlane] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPlane().then((res) => {
      console.log("plane => ", res);
      console.log(res.static_time);
      setTimeEvents(res);
    });

    setLoading(false);
  }, [loading]);

  async function getPlane() {
    let plane = await getDoc(db, "plane", plane_id);
    setPlane(plane);
    return plane;
  }
  async function setTimeEvents(planeObject) {
    let staticTimeList = [];
    let recurringTimeList = [];
    let finalTimeList = [];
    staticTimeList = await createStaticTimeList(planeObject);
    recurringTimeList = await createRecurringTimesList(planeObject, 1);

    finalTimeList = recurringTimeList.concat(staticTimeList);
    console.log("finalTimeList => ", finalTimeList);
    console.table(finalTimeList);
    setAllEvents(finalTimeList);
  }
  // ===================== Test Func ===================
  // function handleCal(itemId) {
  //   const value = e.target.value;
  //   //copying data to temp variable so that we do not directly mutate original state
  //   //const tempWorkingHours = [...data.working_hours];
  //   const tempAllEvents = [...allEvents];

  //   //findIndex to find location of item we need to update
  //   // let index = tempWorkingHours.findIndex(item => item.id == itemId);
  //   let index = tempAllEvents.findIndex((item) => item.id == itemId);
  //   // -1 check to see if we found that object in working hours
  //   // if(index != -1){
  //   //    tempWorkingHours[index] = {
  //   //      ...tempWorkingHours[index], //keeping existing values in object
  //   //      [property]: value  //here property can be "price" or "description"
  //   //    }

  //   if (index != -1) {
  //     tempWorkingHours[index] = {
  //       ...tempWorkingHours[index], //keeping existing values in object
  //       [property]: value, //here property can be "price" or "description"
  //     };
  //   }

  //   setData({ ...data, working_hours: tempWorkingHours });
  // }

  // ===================== ===================
  // ===================== Test Func ===================

  const handleAddDay = (newWord, days) => {
    //alert("handleAddDay => " + JSON.stringify(newWord) + " days => " + days);
    //setWord(newWord);
  };
  // ===================== Test Func End ===================
  //
  //  //
  //  //
  //  //
  //

  if (loading) {
    return <Spinner animation="border" variant="warning" />;
  }
  return (
    <div className="calendar">
      <h1>Calendar</h1>
      <h2>Add New Booking</h2>
      {/* <h2>{plane_id}</h2> */}
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
        eventPropGetter={(event) => {
          const backgroundColor =
            event.color == "lightgreen" ? "lightgreen" : "blue";
          return { style: { backgroundColor } };
        }}
        onSelectEvent={handleEventSelection}
      />

      {/* ==================================== Tabs  ====================================   */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Static" {...a11yProps(0)} />
        <Tab label="Recurring" {...a11yProps(1)} />
        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
      </Tabs>
      <div>
        <SwipeableViews
          axis={"x-reverse"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {/* Item One */}
            <div className="reg-row-container">
              <AddEventComponent handleAddCEvent={handleAddCEvent} />
              <UpdateEventComponent
                selectedStaticTime={staticTime}
                disabled={disable}
                //time_id={timeId}
                handleUpdateEvent={handleUpdateEvent}
                handleDeleteEvent={handleDeleteEvent}
                key={staticTime.time_id}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {/* Item Two */}
            {/* 
            Child Component call Parent Component
            https://stackoverflow.com/questions/55726886/react-hook-send-data-from-child-to-parent-component 
            */}
            <div className="reg-row-container">
              <AddRecEventComponent
                onHandleAddDay={handleAddDay}
                handleAddRecurringEvent={handleAddRecurringEvent}
              />
              <UpdateRecEventComponent
                selectedRecurringTime={recurringTime}
                disabledRec={disableRec}
                // time_id={timeId}
                handleUpdateRecEvent={handleUpdateRecEvent}
                handleDeleteRecEvent={handleDeleteRecEvent}
                key={recurringTime.time_id}
              />
              <br />
              <br />
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>

      {/*  ====================================  Tabs end  ==================================== */}
    </div>
  );
}

export default CalendarComponent;
