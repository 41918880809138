import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { setYears } from "../Utils/years";
import {
  Badge,
  Button,
  Col,
  CloseButton,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import PlaneCard from "./PlaneCard";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

//import ReactMapGL, { Marker } from "react-map-gl";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./PlaneSearch.css";
import axios from "axios";
import { useParams } from "react-router";
// import geofire from "geofire";
import app from "../Main/base";

import { hashFunc } from "../Utils/Utils";

// import { geofire } from "geofire-common";
import * as geofire from "geofire-common";

import Map, { Marker, FitBoundsOptions } from "react-map-gl";
import mainMakerImage from "../../images/marker-icons/mapbox-marker-icon-20px-red.png";

import greenPin from "../../images/marker-icons/mapbox-marker-icon-20px-green.png";
import bluePin from "../../images/marker-icons/mapbox-marker-icon-20px-blue.png";
import PlaneGrid from "./PlaneGrid";

const PlaneFilters = ({
  handleMapView,
  mapView,
  handleFilterSubmit,
  handleResetFilters,
}) => {
  const years = setYears();
  const { height, width } = useWindowDimensions();

  // ====================================================================================
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: {
      radius: 100,
      make: "",
      model: "",
      category: "",
      year: {
        start: "0000",
        end: "0000",
      },
      price_hourly: {
        min: 0,
        max: 0,
      },
      price_flat: {
        min: 0,
        max: 0,
      },
    },
  });

  const watchYearFields = watch(["year.start", "year.end"]); // you can also target specific fields by their names

  const watchHourlyFields = watch(["price_hourly.min", "price_hourly.max"]); // you can also target specific fields by their names
  const hourlyHandleChange = (minMax, event) => {
    // console.log("Price Per Hour ", event.target.value);
    // console.log("minMax ", minMax);

    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    if (minMax === -1) {
      setValue("price_hourly.min", value);
    } else if (minMax === 1) {
      setValue("price_hourly.max", value);
    }
  };

  const watchFlatFields = watch(["price_flat.min", "price_flat.max"]); // you can also target specific fields by their names
  const flatHandleChange = (minMax, event) => {
    // console.log("Price Flat", event.target.value);
    // console.log("minMax ", minMax);

    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    if (minMax === -1) {
      setValue("price_flat.min", value);
    } else if (minMax === 1) {
      setValue("price_flat.max", value);
    }
  };

  const onSubmit = async (data) => {
    if (goodDateOrder && goodHourlyOrder && goodFlatOrder) {
      handleFilterSubmit(data);
    }
  };

  // ====================================================================================
  function goodDateOrder() {
    if (watchYearFields[0] <= watchYearFields[1]) {
      return true;
    }
    return false;
  }

  function goodHourlyOrder() {
    if (watchHourlyFields[0] <= watchHourlyFields[1]) {
      return true;
    }
    return false;
  }

  function goodFlatOrder() {
    if (watchFlatFields[0] <= watchFlatFields[1]) {
      return true;
    }
    return false;
  }

  // ====================================================================================

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="nav-contents py-2 px-0 gap-2 d-flex width-100 align-items-center">
              <div className="filters-container">
                {" "}
                {/* Header {address || "Your Location"} */}
                <Row className="g-2">
                  <Col md>
                    <FloatingLabel controlId="radius" label="Radius">
                      <Form.Select
                        aria-label="Floating label select example"
                        className="mb-3"
                        {...register("radius")}
                      >
                        <option value={1}>1 mi</option>
                        <option value={5}>5 mi</option>
                        <option value={10}>10 mi</option>
                        <option value={20}>20 mi</option>
                        <option value={30}>30 mi</option>
                        <option value={40}>40 mi</option>
                        <option value={50}>50 mi</option>
                        <option value={60}>60 mi</option>
                        <option value={70}>70 mi</option>
                        <option value={80}>80 mi</option>
                        <option value={90}>90 mi</option>
                        <option value={100}>100 mi</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel controlId="make" label="Make">
                      <Form.Control className="mb-3" {...register("make")} />
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <FloatingLabel controlId="model" label="Model">
                      <Form.Control className="mb-3" {...register("model")} />
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <OverlayTrigger
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover id={`popover-positioned-$'bottom'`}>
                          <Popover.Header as="h3">{"Set Dates"}</Popover.Header>
                          <Popover.Body className="reg-flex-space-between">
                            <FloatingLabel
                              controlId="category"
                              label="MIN"
                              className="reg-mr-20"
                            >
                              <Form.Select
                                className="mb-3"
                                {...register("year.start")}
                                // isInvalid={!!errors.year.start}
                              >
                                <option value={"0000"}>0000</option>
                                {years.map((e, key) => (
                                  <option value={e} key={key} label={e}>
                                    {e}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel controlId="category" label="MAX">
                              <Form.Select
                                className="mb-3"
                                {...register("year.end")}
                              >
                                <option value={"0000"}>0000</option>
                                {years.map((e, key) => (
                                  <option value={e} key={key} label={e}>
                                    {e}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div>
                        <FloatingLabel controlId="year" label="Years">
                          <Form.Control
                            className="mb-3"
                            value={`${watchYearFields[0]} - ${watchYearFields[1]}`}
                          />
                        </FloatingLabel>
                        {!goodDateOrder() ? (
                          <Form.Text className="text-danger">
                            {"Fix Date Order!!"}
                          </Form.Text>
                        ) : (
                          <></>
                        )}
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col md>
                    <FloatingLabel controlId="category" label="Category">
                      <Form.Select
                        aria-label="Category"
                        className="mb-3"
                        {...register("category")}
                      >
                        <option value=""></option>
                        <option value="Single Engine Airplane (Land)">
                          Single Engine Airplane (Land)
                        </option>
                        <option value="Multi Engine Airplane (Land)">
                          Multi Engine Airplane (Land)
                        </option>
                        <option value="Single Engine Airplane (Sea)">
                          Single Engine Airplane (Sea)
                        </option>
                        <option value="Multi Engine Airplane (Sea)">
                          Multi Engine Airplane (Sea)
                        </option>
                        <option value="Rotorcraft">Rotorcraft</option>
                        <option value="Glider">Glider</option>
                        <option value="Lighter than air">
                          Lighter than air
                        </option>
                        <option value="UAV">UAV</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md>
                    <OverlayTrigger
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover id={`popover-positioned-$'bottom'`}>
                          <Popover.Header as="h3">
                            {"Set Hourly Price Range USD $"}
                          </Popover.Header>
                          <Popover.Body className="reg-flex-space-between">
                            <FloatingLabel
                              controlId="category"
                              label="MIN"
                              className="reg-mr-20"
                            >
                              <Form.Control
                                aria-label="MIN"
                                type="number"
                                min="0"
                                max="500"
                                {...register("price_hourly.min")}
                              />
                              <Form.Range
                                onChange={(e) => hourlyHandleChange(-1, e)}
                                min="0"
                                max="500"
                              />
                            </FloatingLabel>

                            <FloatingLabel controlId="category" label="MAX">
                              <Form.Control
                                aria-label="MAX"
                                type="number"
                                min="0"
                                max="500"
                                {...register("price_hourly.max")}
                              />
                              <Form.Range
                                onChange={(e) => hourlyHandleChange(1, e)}
                                min="0"
                                max="500"
                              />
                            </FloatingLabel>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div>
                        <FloatingLabel
                          controlId="priceRange"
                          label={"Price Hourly"}
                        >
                          <Form.Control
                            className="mb-3"
                            value={`${watchHourlyFields[0]} - ${watchHourlyFields[1]}`}
                          />
                        </FloatingLabel>
                        {!goodHourlyOrder() ? (
                          <Form.Text className="text-danger">
                            {"Fix Price Order!!"}
                          </Form.Text>
                        ) : (
                          <></>
                        )}
                      </div>
                    </OverlayTrigger>
                  </Col>
                  {/* =================================== Flat Rate  ===================================*/}
                  {/* <Col md>
                    <OverlayTrigger
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover id={`popover-positioned-$'bottom'`}>
                          <Popover.Header as="h3">
                            {"Set Flat Price Range USD $"}
                          </Popover.Header>
                          <Popover.Body className="reg-flex-space-between">
                            <FloatingLabel
                              controlId="category"
                              label="MIN"
                              className="reg-mr-20"
                            >
                              <Form.Control
                                aria-label="MIN"
                                type="number"
                                min="0"
                                max="500"
                                {...register("price_flat.min")}
                              />
                              <Form.Range
                                onChange={(e) => flatHandleChange(-1, e)}
                                min="0"
                                max="500"
                              />
                            </FloatingLabel>

                            <FloatingLabel controlId="category" label="MAX">
                              <Form.Control
                                aria-label="MAX"
                                type="number"
                                min="0"
                                max="500"
                                {...register("price_flat.max")}
                              />
                              <Form.Range
                                onChange={(e) => flatHandleChange(1, e)}
                                min="0"
                                max="500"
                              />
                            </FloatingLabel>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div>
                        <FloatingLabel
                          controlId="priceRange"
                          label={"Price FLat Rate"}
                        >
                          <Form.Control
                            className="mb-3"
                            value={`${watchFlatFields[0]} - ${watchFlatFields[1]}`}
                          />
                        </FloatingLabel>
                        {!goodFlatOrder() ? (
                          <Form.Text className="text-danger">
                            {"Fix Price Order!!"}
                          </Form.Text>
                        ) : (
                          <></>
                        )}
                      </div>
                    </OverlayTrigger>
                  </Col> */}
                  {/* =================================== Flat Rate End ===================================*/}
                  <Col md>
                    <Button
                      className="mb-3"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Apply Filters
                    </Button>
                  </Col>
                  <Col md>
                    <Button
                      className="mb-3"
                      onClick={() => {
                        reset();
                        handleResetFilters();
                      }}
                      variant="secondary"
                    >
                      Reset Filters
                    </Button>
                  </Col>

                  {/* <Button onClick={getCoordinates}>Get Coordinates</Button> */}
                  {width < 1024 ? (
                    <Col>
                      <Button
                        className="md-3"
                        onClick={() => {
                          handleMapView();
                        }}
                        variant="light"
                      >
                        {mapView ? "View List" : "View Map"}
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Form>
  );
};

/// Get viewport/window Dimensions in ReactJS
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
/// ========================================

export default PlaneFilters;
