import Carousel from "react-material-ui-carousel";
import { useState } from "react";
import { useParams } from "react-router";
import {
  Paper,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const plane = {
  pictures: [
    "https://upload.wikimedia.org/wikipedia/commons/a/ae/Cessna_172S_Skyhawk_SP%2C_Private_JP6817606.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/5/5d/C-172A_N7481T_at_Anderson%2C_Indiana%2C_USA.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/5/54/Cessna172Skyhawk1957model01.jpg",
  ],
  name: "Cessna 172 Skyhawk",
  description:
    "The Cessna 172 Skyhawk is an American four-seat, single-engine, high wing, fixed-wing aircraft made by the Cessna Aircraft Company. First flown in 1955, more 172s have been built than any other aircraft. It was developed from the 1948 Cessna 170, using tricycle undercarriage, rather than a tail-dragger configuration.",
  engineHours: 700,
  avionics: [
    "Apollo MX-20 MFD",
    "KX 155A Nav/Comm",
    "2nd KX 155A Nav/Comm With Glideslope",
    "KI 208 VOR/LOC Indicator",
    "KI 209A VOR/LOC/GS Indicator with GPS Interface",
    "KMA 28 Audio Panel/Marker Beacon/Four-position, Voice-activated Intercom",
    "KT 76C Mode C Transponder",
    "KLN 94 Color Moving-map GPS-IFR",
    "KAP 140 Two-axis Autopilot with Altitude Preselect",
    "uAvionix Tail Beacon with ADS-B Out",
    "Avionics Cooling Fan",
    "Emergency Locator Transmitter",
    "Davtron Digital Clock",
  ],
  price: 300,
};

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "40rem",
    "object-fit": "cover",
  },
}));

function Picture(props) {
  const classes = useStyles();
  return <img src={props.imageUrl} object-fit="cover" class={classes.image} />;
}

const PlaneProfile = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  // const { pictures, name, description, engineHours, avionics, price } = props;
  const { pictures, name, description, engineHours, avionics, price } = plane;
  const [selectedDate, setSelectedDate] = useState(
    new Date("2014-08-18T21:11:54")
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <Carousel navButtonsAlwaysVisible classes={classes.carousel}>
        {pictures.map((item, i) => (
          <Picture key={i} imageUrl={item} />
        ))}
      </Carousel>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h3" align="center">
            {name}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography align="center">{description}</Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid item sm={12} md={7}>
              <Typography variant="h6" color="primary">
                Rules and Guidelines
              </Typography>
              Rules and Guidelines
              <Typography variant="h6" color="primary">
                Avionics
              </Typography>
              <List>
                {avionics.map((item, i) => (
                  <ListItem key={i}>
                    <Typography variant="body">{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item sm={12} md={5}>
                <Box mb={3}>
                  <Typography variant="h6" color="primary" component="span">
                    Engine Hours
                  </Typography>
                  <Typography variant="h6" component="span">
                    {" " + engineHours}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" color="primary" component="span">
                    Price
                  </Typography>
                  <Typography variant="h6" component="span">
                    {" $" + price + "/hr"}
                  </Typography>
                </Box>
                <Typography variant="h6" color="primary">
                  Book Plane
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="start-date"
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="start-time"
                      label="Start Time"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="end-date"
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="end-time"
                      label="End Time"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      href="/PilotSignup"
                    >
                      Book Plane
                    </Button>
                    {id}
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default PlaneProfile;
