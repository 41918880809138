import firebase from "firebase/app"; // <---- This must be first
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Spinner } from "react-bootstrap";
import { validEmail } from "../../../Utils/validation";
import "./Login.css";

export const Login = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({});
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const fb = {
    auth: firebase.auth(),
    storage: firebase.storage(),
    firestore: firebase.firestore(),
  };
  const history = useHistory();
  const [serverError, setServerError] = useState("");

  const onSubmit = async ({ email, password }) => {
    try {
      await fb.auth.signInWithEmailAndPassword(email, password);

      history.push("/profile");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setServerError("Invalid credentials");
      } else if (error.code === "auth/user-not-found") {
        setServerError("No account for this email");
      } else {
        setServerError("Something went wrong :(");
      }
    }
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <div className="auth-form">
      <h1 style={{ color: "white" }}>Login</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={!!errors.email}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: validEmail,
                message: `That doesn't look like a valid email`,
              },
            })}
          />
          <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!errors.password}
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Minimum length is 8 characters",
              },
              maxLength: {
                value: 20,
                message: "Maximum length is 20 characters",
              },
            })}
          />
          <Form.Text className="text-danger">
            {errors.password?.message}
          </Form.Text>
        </Form.Group>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              Login
              <Spinner style={{ marginLeft: 8 }} animation="border" size="sm" />
            </>
          ) : (
            "Login"
          )}
        </Button>
      </Form>

      {!!serverError && <div className="error">{serverError}</div>}
    </div>
  );
};
