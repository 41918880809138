import { PlaneForm } from "./EditPlane/Forms/PlaneForm";

export const CreatePlane = ({ expanded, onChange }) => {
  return (
    <div>
      <PlaneForm />
    </div>
  );
};

export default CreatePlane;
