import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ListItem, ListItemText, List, Button } from "@material-ui/core";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
}));

export default function PlaneTab({
  expanded,
  onChange,
  plane_id,
  planeData,
  userName,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  //   const [expanded, setExpanded] = React.useState(false);

  //props
  //   const plane_id = plane_id;
  const plane = planeData;
  //   const userName = userName;

  //   const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  useEffect(() => {
    //setUserName(getUserNameById(plane.owner_id));

    setLoading(false);
  }, [loading]); //  Run once on load

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return (
    // <div className={classes.root}>
    <Accordion
      onChange={onChange}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      // expanded={expanded === "panel1"}
      // onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>May 4, 2021@2:00pm</Typography>
        <Typography className={classes.secondaryHeading}>
          {plane.base_plane_location}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List className={classes.list}>
          <ListItem>
            <ListItemText>
              <Typography>Location</Typography>
            </ListItemText>
            <Typography>{plane.base_plane_location}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>Plane Owner</Typography>
            </ListItemText>
            <Typography>{userName}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography>Edit Plane</Typography>
            </ListItemText>

            <Typography>
              <Link
                to={{
                  pathname: `/edit-plane/${plane_id}`,
                }}
              >
                View This Plane
              </Link>
            </Typography>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
    // </div>
  );
}
