import "bootstrap/dist/css/bootstrap.css";
import { alpha } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ButtonGroup, Button } from "react-bootstrap";
import { withRouter, Route } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import {
  getImageUrl,
  getUserAvatarById,
  getUserNameById,
  getUserById,
  getDate,
  iAmOwner,
} from "../../Utils/Utils";
import app from "../../Main/base";
import ApprovedItems from "../../Models/ApprovedItems";

import TABLE_BODY from "./data.json";
import TABLE_BODY2 from "./data2.json";
import { tr } from "date-fns/locale";

// Create table headers consisting of 4 columns.
const STORY_HEADERS = [
  {
    prop: "name",
    title: "Name",
    isFilterable: true,
  },
  {
    prop: "username",
    title: "Username",
  },
  {
    prop: "location",
    title: "Location",
  },
  {
    prop: "date",
    title: "Last Update",
  },
  {
    prop: "score",
    title: "Score",
    isSortable: true,
  },

  {
    prop: "actions",
    title: "Score",
    isSortable: true,
    cell: (row) => {
      return row.score + 10;
    },
  },
  {
    prop: "actions",
    title: "Avatar",
    cell: (row) => {
      return (
        <AvatarWithImage
          owner_id={"XQSBVGvJiZc0qmoHGCS5sOcGGeD2"}
          pilot_id={"XQSBVGvJiZc0qmoHGCS5sOcGGeD2"}
        />
      );
    },
  },
];

const HEADERS = [
  {
    prop: "actions",
    title: "Avatar",
    cell: (row) => {
      return <AvatarWithImage owner_id={row.ownerId} pilot_id={row.pilotId} />;
    },
  },
  {
    prop: "pilotName",
    title: "Pilot id/Name",
    // isFilterable: false,
    // cell: (row) => {
    //   let res;
    //   (async () => {
    //     res = await name(row);
    //   })();
    //   console.log("78", res);
    //   return res;
    // },
  },
  {
    prop: "ownerName",
    title: "Owner id/Name",
    // cell: (row) => {
    //   return "hurry";
    // },
  },
  {
    prop: "startDate",
    title: "Start Date",
    isFilterable: true,
    // isSortable: true,
    // cell: (row) => {
    //   return getDate(row.date_start);
    // },
  },
  {
    prop: "location",
    title: "Location",
    isSortable: true,
  },
  {
    prop: "plane",
    title: "Planes",
    // cell: (row) => {
    //   return "Cessna, 172";
    // },
  },
  {
    prop: "actions",
    title: "",
    cell: (row) => {
      return (
        <Route
          render={({ history }) => (
            <Button
              type="button"
              onClick={() => {
                //   history.push("/");
                history.push(`/trip-contract/${row.key}`);
              }}
            >
              View Trip!
            </Button>
          )}
        />
      );
    },
  },
  {
    prop: "actions",
    title: "",
    cell: (row) => {
      return (
        <Route
          render={({ history }) => (
            <Button
              type="button"
              variant="success"
              onClick={() => {
                //   history.push("/");
                history.push(`/messages`);
              }}
            >
              Message
            </Button>
          )}
        />
      );
    },
  },
];

// Then, use it in a component.
export default function RequestTableComponent(props) {
  const [approvedList, SetApprovedList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [k, setK] = useState("");

  const [l, setL] = useState("");

  useEffect(() => {
    makeTripsList();
    // setPiloteName(approvedList);
    setLoading(false);
  }, [loading]); //  Run once on load

  const makeTripsList = async () => {
    // SetApprovedList(function () {
    //   return props.trips.filter((trip) => trip.trip_phase === 1);
    // });

    let filteredList = props.trips.filter((trip) => trip.trip_phase === 0);

    // console.log("RequestedList start " + filteredList);
    let list = [];
    for (let trip of filteredList) {
      ///

      //trip.owner_id
      let owner_Name = "jotoro";
      await getUserNameById(trip.owner_id)
        .then((res) => {
          // console.log("res 1 " + res);
          owner_Name = res;
          setK(res);
          // console.log("res 1 k " + k);
        })
        .then((doc) => {});

      //trip.pilot_id
      let pilot_Name = "baki";

      //getNameP(trip.pilot_id);

      //trip.date_start
      let start_Date = getDate(trip.date_start);

      //trip.planes_location
      let planes_Location = trip.planes_location;

      //trip.plane_id = VMDS5tXDyI0s88pjWKKX
      let planes_Name = "Cessna, 172";

      const newItem = {
        ownerName: owner_Name,
        ownerId: trip.owner_id,
        pilotName: pilot_Name,
        pilotId: trip.pilot_id,
        startDate: start_Date,
        location: planes_Location,
        plane: planes_Name,
        key: trip.key,
      };

      // console.log("Trip table Object ", newItem);
      list.push(newItem);

      ///
    }
    for (let trip of list) {
      await getUserNameById(trip.pilotId)
        .then((res) => {
          // console.log("res 2 " + res);
          trip.pilotName = res;
          // console.log("item.pilotName  " + trip.pilotName);
        })
        .then((doc) => {});
    }

    // console.log("list => ", list);
    SetApprovedList(list);

    // console.log("ApprovedList " + approvedList);
  };

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }
  return (
    <DatatableWrapper
      //   body={TABLE_BODY}
      //   headers={STORY_HEADERS}
      // body={TABLE_BODY2}
      body={approvedList}
      headers={HEADERS}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20],
        },
      }}
    >
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
        >
          <PaginationOptions />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Pagination />
        </Col>
      </Row>
      <Table>
        <TableHeader />
        <TableBody />
      </Table>
    </DatatableWrapper>
  );
}

function AvatarWithImage(props) {
  // Profile image of other person
  const [currentProfileImage, setCurrentProfileImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProfileImages();
    setLoading(false);
  }, [loading]); //  Run once on load

  const setProfileImages = () => {
    if (!iAmOwner()) {
      getUserAvatarById(props.owner_id).then(function (values) {
        // console.log("values ", values);
        let item = getImageUrl(app.storage(), values);
        item.then((result) => {
          // console.log("item 1 ------- ", result);
          setCurrentProfileImage(result.toString());
          // console.log("fuel link ------- ", currentProfileImage);
        });
      });
    } else {
      getUserAvatarById(props.pilot_id).then(function (values) {
        // console.log("values ", values);
        let item = getImageUrl(app.storage(), values);
        item.then((result) => {
          // console.log("item 1 ------- ", result);
          setCurrentProfileImage(result.toString());
          // console.log("fuel link ------- ", currentProfileImage);
        });
      });
    }
  };

  function iAmOwner() {
    let bool = props.owner_id === app.auth().currentUser.uid;
    // console.log("bool => ", bool);
    // setImOwner(bool);
    return bool;
  }

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }

  return <Avatar aria-label="avatar" src={currentProfileImage}></Avatar>;
}
