import React, { useEffect, useState, Component } from "react";
import { useForm } from "react-hook-form";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarComponent.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";

//import TimePicker from "react-time-picker";

import Spinner from "react-bootstrap/Spinner";

import app from "../../Main/base";
import RecurringTime from "../../Models/RecurringTime";
import StaticTime from "../../Models/StaticTime";
import {
  getDoc,
  getImageUrl,
  updateDoc,
  updateDoc2,
  toDateTime,
  reload,
} from "../Utils";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  FormHelperText,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

function UpdateRecEventComponent({
  selectedRecurringTime,
  handleUpdateRecEvent,
  handleDeleteRecEvent,
  disabledRec,
  time_id,
}) {
  const [selectedTime, setSelectedTime] = useState(selectedRecurringTime);
  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit: handleSubmitUpdate,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: selectedRecurringTime,
  });
  // ================= ========  Update Dates ========= =================
  const [dateError, setDateError] = useState("");
  //
  //
  //
  const [selectedUpdateStartDate, setSelectedUpdateStartDate] = useState(
    selectedRecurringTime.start
  );
  const [startChange, setStartChange] = useState(false);
  const handleUpdateStartDateChange = (date) => {
    if (!date) {
      setDateError("Date can not be empty");
      return null;
    }

    if (selectedUpdateEndDate != null) {
      date.getTime() > selectedUpdateEndDate.getTime()
        ? setDateError("Dates Out Of Order!")
        : setDateError("");
    }
    setSelectedUpdateStartDate(date);
    setStartChange(true);
  };
  //
  //
  //
  const [selectedUpdateEndDate, setSelectedUpdateEndDate] = useState(
    selectedRecurringTime.end
  );
  const [endChange, setEndChange] = useState(false);
  const handleUpdateEndDateChange = (date) => {
    if (!date) {
      setDateError("Date can not be empty");
      return null;
    }
    if (selectedUpdateStartDate != null) {
      date.getTime() < selectedUpdateStartDate.getTime()
        ? setDateError("Dates Out Of Order!")
        : setDateError("");
    }
    setSelectedUpdateEndDate(date);
    setEndChange(true);
  };
  //  ================= ======== Update Dates End ========= =================

  async function handleUpdateFunc(data) {
    console.log("data, ", data);
    console.log("isDirty, ", isDirty);
    if (isDirty || startChange || endChange) {
      if (
        selectedUpdateStartDate != null &&
        selectedUpdateEndDate != null &&
        dateError == ""
      ) {
        let recurringTime = {
          time_id: data.time_id,
          end: selectedUpdateEndDate,
          flat_rate: data.flat_rate,
          pilot_id: "",
          rate_per_hour: data.rate_per_hour,
          start: selectedUpdateStartDate,
          title: data.title,
          color: "lightgreen",
        };
        handleUpdateRecEvent(recurringTime);
      } else if (
        (selectedUpdateStartDate != null || selectedUpdateEndDate != null) &&
        dateError != ""
      ) {
        setDateError("Date can not be empty");
      }
    }
  }

  async function handleDeleteFunc(data) {
    handleDeleteRecEvent(data);
  }

  return (
    <div style={disabledRec ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      {/* <h4>{JSON.stringify(selectedRecurringTime)}</h4> */}
      <Form
        //onSubmit={handleSubmitUpdate(onSubmit)}
        className="px-3 pb-5"
      >
        <Form.Label>Update A Event</Form.Label>
        <div>
          <InputGroup
            className="mb-3 reg-row-container"
            style={{ pointerEvents: "none", opacity: "0.9" }}
          >
            <Form.Group>
              <Form.Label>Day Of Week</Form.Label>
              <Form.Control
                className="mb-3"
                value={selectedRecurringTime.title}
                // isInvalid={!!errors.title}
                // {...register("title", {
                //   required: "Title is required",
                // })}
              />
              {/* <Form.Text className="text-danger">
                {errors.title?.message}
              </Form.Text> */}
            </Form.Group>
          </InputGroup>
          <InputGroup className="mb-3 reg-row-container">
            <Box style={{ width: "50%", marginRight: "1px" }}>
              <Typography>
                Input your Start Date and time here please
              </Typography>
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                margin="normal"
                id="start-date"
                // format="E, dd MMM yyy - hh:mm a"
                value={selectedUpdateStartDate}
                onChange={handleUpdateStartDateChange}
                style={{ width: "50%" }}
              />
            </MuiPickersUtilsProvider>
          </InputGroup>
          <InputGroup className="mb-3 reg-row-container">
            <Box style={{ width: "50%", marginRight: "1px" }}>
              <Typography> Input your End Date and time here please</Typography>
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                margin="normal"
                id="start-date"
                // format="E, dd MMM yyy - hh:mm a"
                value={selectedUpdateEndDate}
                onChange={handleUpdateEndDateChange}
                style={{ width: "50%" }}
              />
            </MuiPickersUtilsProvider>
          </InputGroup>
          {dateError && (
            <FormHelperText className="carousel-error error-text">
              {dateError}
            </FormHelperText>
          )}
          <InputGroup className="mb-3">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Price Per Hour</Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="number"
                    isInvalid={!!errors.rate_per_hour}
                    {...register("rate_per_hour", {
                      required: "Price per hour is required",
                    })}
                  />
                  <Form.Text className="text-danger">
                    {errors.rate_per_hour?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Flate Rate</Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="number"
                    isInvalid={!!errors.flat_rate}
                    {...register("flat_rate", {
                      required: "Flate Rate is required",
                    })}
                  />
                  <Form.Text className="text-danger">
                    {errors.flat_rate?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </InputGroup>
          <br />
          <InputGroup className="mb-3">
            <ButtonGroup
              size="lg"
              className="mb-3"
              style={{ left: "auto", width: "100%" }}
            >
              <Button
                type="submit"
                //disabledRec={isSubmittingUpdate}
                size="lg"
                className="mt-3 w-100 reg-mr-5"
                onClick={handleSubmitUpdate(handleUpdateFunc)}
              >
                Update Event
              </Button>
              <Button
                type="submit"
                //disabledRec={isSubmittingUpdate}
                styles={{
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  display: "block",
                }}
                size="lg"
                className="mt-3 w-100 reg-mr-5"
                // onClick={handleDeleteRecEvent}
                onClick={handleSubmitUpdate(handleDeleteFunc)}
              >
                Delete Event
              </Button>
            </ButtonGroup>
          </InputGroup>
        </div>
      </Form>
    </div>
  );
}

export default UpdateRecEventComponent;
