import React, { useState, useContext } from "react";
import firebase from "firebase/app"; // <---- This must be first
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  FormControl,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Card, CardGroup, ListGroup } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

import SearchIcon from "@material-ui/icons/Search";
import { purple } from "@material-ui/core/colors";
import { Input } from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";

import { fade, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import SearchComponent from "./Search/SearchComponent";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  Link,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: props.backgroundColor,
    color: theme.color,
  }),
  divider: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: "10rem",
  },
  Modal: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
}));

const HeaderLoggedIn = () => {
  const classes = useStyles();
  function handleHistory() {
    firebase.auth().signOut().then();
  }

  const history = useHistory();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      history.push(`/search/${event.target.value}`);
    }
  };

  const [searchResults, setSearchResults] = useState([
    "Hello1",
    "Hello2",
    "Hello3",
    "Hello4",
    "Hello5",
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <SearchComponent />
      <Link className="nav-link p-0 text-nowrap" to="/trips">
        Trips
      </Link>
      <Link className="nav-link p-0 text-nowrap" to="/messages">
        Messages
      </Link>
      <Link className="nav-link p-0 text-nowrap" to="/list-your-plane">
        List Your Planes
      </Link>
      <Link className="nav-link p-0 text-nowrap" to="/profile">
        Profile
      </Link>
      <Button onClick={handleShow}>LogOut</Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>LogOut</Modal.Title>
        </Modal.Header>
        <Modal.Body>Loging out are you sure??</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" href="/" onClick={handleHistory}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HeaderLoggedIn;
