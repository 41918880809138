import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  cover: {
    height: 250,
  },
}));

const PlaneCard = ({ planeObj, planeId }) => {
  // const { title, location, coverImage, hostRating, price } = props;
  const classes = useStyles();
  const history = useHistory();

  const coverImage =
    "https://upload.wikimedia.org/wikipedia/commons/6/69/N975QS_2002_Cessna_750_C-N_750-0175_Citation_X_%287039507775%29.jpg";

  const goToProfile = () => {
    let path = `/PlaneProfile/${planeId}`;
    history.push(path);
  };

  return (
    <Card
      align="center"
      href="/PilotSignup"
      onClick={goToProfile}
      className="plane-card"
    >
      <CardHeader title={planeId} />
      <CardMedia
        className={classes.cover}
        image={coverImage}
        title="Live from space album cover"
      />
      <CardContent>
        <Grid container>
          <Grid item xs={6} align="left">
            <Typography>Host Rating: {4.7}</Typography>
            <Typography>
              Price: ${planeObj.fields.price_perhour_usd.integerValue}/hr
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography>
              {" "}
              Location: {planeObj.fields.base_plane_location.stringValue}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default PlaneCard;
