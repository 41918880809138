import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
  Badge,
  Button,
  Col,
  CloseButton,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import app from "../../../Main/base";
import firebase from "firebase";
import { getDoc, getCollection, updateDoc2 } from "../../../Utils/Utils";

import { usePilotImages } from "../../../../hooks/usePilotImages";
import { CarouselLayout } from "../../../Utils/Carousels/CarouselLayout";
import { CarouselComponent } from "../../../Utils/Carousels/CarouselComponent";

const TripContractPilotForm = ({ tripId, pilotChecklist_Id }) => {
  const [loading, setLoading] = useState(true);
  const [mainTrip, setMaintrip] = useState(null);
  const [pilotChecklist, setPilotChecklist] = useState({});

  const collection = `Trips/${tripId}/checklist_pilot`;
  const checkListId = pilotChecklist_Id;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    delayError: 800,
    defaultValues: pilotChecklist,
  });

  // example  &&& const { displayImages, inspectionImages } = usePlaneImages(id);
  const { images, imagePath } = usePilotImages(collection, pilotChecklist_Id);

  const onSubmit = async (data) => {
    console.log("data => ", data);
    await updateDoc2(app.firestore(), collection, checkListId, data);
  };

  //change the defualt to equal the sub trip

  // const getTrip = async () => {
  //   let doc = await getDoc(app.firestore(), "Trips", tripId);
  //   console.log("main trip doc => ", doc);
  //   setMaintrip(doc);
  //   //ownerChecklist = doc;
  // };

  const getPilotChecklist = async () => {
    // let collection = `Trips/${tripId}/checklist_pilot`;
    let doc = await getDoc(app.firestore(), collection, checkListId);
    setPilotChecklist(doc);
    console.log(" pilotChecklist ", pilotChecklist);
  };

  useEffect(() => {
    getPilotChecklist().then(() => {
      //console.log(" pilotChecklist 2 ", pilotChecklist);
      setLoading(false);
    });
    setLoading(false);
  }, [loading]); //  Run once on load

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(pilotChecklist);
  }, [pilotChecklist]);
  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //  //
  //

  if (loading) {
    return <h1>loading firebase data...</h1>;
  }
  return (
    <div>
      {/* Change the var for the pilot checklist */}
      <Form onSubmit={handleSubmit(onSubmit)} className="px-3 pb-5">
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>checkInState </Form.Label>
            <Form.Select
              className="mb-3"
              isInvalid={!!errors.checkInState}
              aria-label="checkInState"
              {...register("checkInState", {
                required: "checkInState is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.checkInState?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>checkInState_Out </Form.Label>
            <Form.Select
              className="mb-3"
              isInvalid={!!errors.checkInState_Out}
              aria-label="checkInState_Out"
              {...register("checkInState_Out", {
                required: "checkInState_Out is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.checkInState_Out?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>logBookReviewState_One</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.logBookReviewState_One}
              className="mb-3"
              isInvalid={!!errors.logBookReviewState_One}
              aria-label="logBookReviewState_One"
              {...register("logBookReviewState_One", {
                required: "logBookReviewState_One is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.logBookReviewState_One?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>logBookReviewState_Two</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.logBookReviewState_Two}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="logBookReviewState_Two"
              {...register("logBookReviewState_Two", {
                required: "logBookReviewState_Two is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.logBookReviewState_Two?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>logBookReviewState_Out</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.logBookReviewState_Out}
              className="mb-3"
              isInvalid={!!errors.logBookReviewState_Out}
              aria-label="logBookReviewState_Out"
              {...register("logBookReviewState_Out", {
                required: "logBookReviewState_Out is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.logBookReviewState_Out?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>preFlight_One</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.preFlight_One}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="preFlight_One"
              {...register("preFlight_One", {
                required: "preFlight_One is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.preFlight_One?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>preFlight_Out</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.preFlight_Out}
              className="mb-3"
              isInvalid={!!errors.preFlight_Out}
              aria-label="preFlight_Out"
              {...register("preFlight_Out", {
                required: "preFlight_Out is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.preFlight_Out?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>thirty_Min_Link</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.thirty_Min_Link}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="thirty_Min_Link"
              {...register("thirty_Min_Link", {
                required: "thirty_Min_Link is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.thirty_Min_Link?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group>
            <Form.Label>thirty_Min_Out</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.thirty_Min_Out}
              className="mb-3"
              isInvalid={!!errors.thirty_Min_Out}
              aria-label="thirty_Min_Out"
              {...register("thirty_Min_Out", {
                required: "thirty_Min_Out is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.thirty_Min_Out?.message}
            </Form.Text>
          </Form.Group>
        </Row>
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>useChecklist </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.useChecklist}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="useChecklist"
              {...register("useChecklist", {
                required: "useChecklist is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.useChecklist?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>normalTakeoff </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.normalTakeoff}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="normalTakeoff"
              {...register("normalTakeoff", {
                required: "normalTakeoff is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.normalTakeoff?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>powerOff_powerStall </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.powerOff_powerStall}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="powerOff_powerStall"
              {...register("powerOff_powerStall", {
                required: "powerOff_powerStall is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.powerOff_powerStall?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>steepTurn </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.steepTurn}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="steepTurn"
              {...register("steepTurn", {
                required: "steepTurn is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.steepTurn?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>simulatedEngineOffProcedures</Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.simulatedEngineOffProcedures}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="simulatedEngineOffProcedures"
              {...register("simulatedEngineOffProcedures", {
                required: "simulatedEngineOffProcedures is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.simulatedEngineOffProcedures?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>shortFieldLanding </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.shortFieldLanding}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="shortFieldLanding"
              {...register("shortFieldLanding", {
                required: "shortFieldLanding is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.shortFieldLanding?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        {/*  */}
        {/* <Row className="mb-1">
          <Form.Group>
            <Form.Label>goNogo </Form.Label>
            <Form.Select
              // defaultValue={ownerChecklist.goNogo}
              className="mb-3"
              isInvalid={!!errors.year}
              aria-label="goNogo"
              {...register("goNogo", {
                required: "goNogo is required",
              })}
            >
              <option value={""}></option>
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
            </Form.Select>
            <Form.Text className="text-danger">
              {errors.goNogo?.message}
            </Form.Text>
          </Form.Group>
        </Row> */}
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              Saving...
              <Spinner style={{ marginLeft: 8 }} animation="border" size="sm" />
            </>
          ) : (
            <>Done</>
          )}
        </Button>
      </Form>
      <CarouselComponent images={images} imagePath={imagePath} />
      {/* <CarouselLayout images={images} /> */}
      <br /> <br /> <br /> <br /> <br />
    </div>
  );
};
export default TripContractPilotForm;
