import React, { useState } from "react";
import app from "../../../Main/base";
import { ListItem, ListItemText, List, Button } from "@material-ui/core";
import Form from "react-bootstrap/Form";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const OwnerTripChecklist = (props) => {
  const classes = useStyles();

  //     -CHECK IN
  const [checkInState_Out, setCheckInState_Out] = useState(false);
  // -meet the pilot at the airport
  const [checkInState, setCheckInState] = useState(false);

  // -LOGBOOK REVIEW
  const [logBookReviewState_Out, setLogBookReviewState_Out] = useState(false);
  // -Bring aircraft maintenence logs for pilots to review One
  const [logBookReviewState_One, setLogBookReviewState_One] = useState(false);
  // -Review pilots logbooks, confirm aircraft experience Two
  const [logBookReviewState_Two, setLogBookReviewState_Two] = useState(false);

  // -PRE FLIGHT
  const [preFlight_Out, setPreFlight_Out] = useState(false);
  // -Watch pilot preflight aircraft One
  const [preFlight_One, setPreFlight_One] = useState(false);

  // -30 MIN SELF CHECKOUT
  const [thirty_Min_Out, setThirty_Min_Out] = useState(false);
  // -(link to checkout page) Link
  const [thirty_Min_Link, setThirty_Min_Link] = useState(false);

  // -FUEL
  const [fuel_Out, setFuel_Out] = useState(false);
  // -take picture or fuel level One
  const [fuel_One, setFuel_One] = useState(false);
  // (Aircraft must be returned with the level of fuel that the aircraft was received with)
  // Two
  const [fuel_Two, setFuel_Two] = useState(false);

  // -FLIGHT
  const [flight_Out, setFlight_Out] = useState(false);
  // -take picture and input hobbs time (before aircraft startup) One
  const [flight_One, setFlight_One] = useState(false);
  // -take picture and input hobbs time (after aircraft shutdown) Two
  const [flight_Two, setFlight_Two] = useState(false);

  const handleSubmit = () => {
    // console.log("Handle Owner submit");

    app
      .firestore()
      .collection(`Trips/${props.id}/checklist_owner`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
        });
      });
    // app
    //   .firestore()
    //   .collection(`Trips/${props.id}/checklist_owner`)
    //   .limit(1)
    //   .get()
    //   .set(
    //     {
    //       checkInState_Out: checkInState_Out,
    //     },
    //     { merge: true }
    //   )
    //   .then(() => {
    console.log("Document successfully updated!");
    //     props.update();
    //   })
    //   .catch((error) => {
    //     // The document probably doesn't exist.
    //     console.error("Error updating document: ", error);
    //   });
  };

  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Check me out"
            onChange={(e) => {
              setCheckInState_Out(e.target.value);

              // app
              //   .firestore()
              //   .collection(`Trips/${props.id}/checklist_owner`)
              //   .limit(1)
              //   .get()
              //  .then((data) => console.log(data));
            }}
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            app
              .firestore()
              .collection(`Trips/${props.id}/checklist_owner`)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  // console.log(doc.id, " => ", doc.data());
                });
              });
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};
export default OwnerTripChecklist;
