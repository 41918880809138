import { useEffect, useState } from "react";
import app from "../components/Main/base";
import { getDoc, getImageUrl } from "../components/Utils/Utils";

export const usePlaneImages = (planeId) => {
  const [displayImages, setDisplayImages] = useState([]);
  const [inspectionImages, setInspectionImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const db = app.firestore();
    const storage = app.storage();

    const getPlane = (planeId) => getDoc(db, "plane", planeId);

    const getInspectionImages = async (images) => {
      try {
        const inspectionImages = images.map(async ({ image_path, name }) => {
          const url = await getImageUrl(storage, image_path);
          return { url, name };
        });

        return Promise.all(inspectionImages);
      } catch (error) {
        throw error;
      }
    };

    const getDisplayImages = async (images) => {
      try {
        const displayImages = images.map(async (path) => {
          const url = await getImageUrl(storage, path);
          return { url };
        });

        return Promise.all(displayImages);
      } catch (error) {
        throw error;
      }
    };

    try {
      const loadImages = async () => {
        const { display_images, inspection_images } = await getPlane(planeId);
        const [displayImages, inspectionImages] = await Promise.all([
          getDisplayImages(display_images),
          getInspectionImages(inspection_images),
        ]);

        setDisplayImages(displayImages);
        setInspectionImages(inspectionImages);
      };

      loadImages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [app, planeId]);

  return { displayImages, inspectionImages, isLoading };
};
