export const defaultPlane = {
  airworthiness_certificate: "",
  altimeter_date: "",
  altimeter_image: "",
  avionics: [],
  base_plane_location: "",
  category: "",
  description: "",
  display_images: [],
  elt_date: "",
  elt_image: "",
  form_one_finished: false,
  form_three_finished: false,
  form_two_finished: false,
  hobbs_time: null,
  make: "",
  model: "",
  n_number_id: "",
  need_preflight_checkout: true,
  one_hundred_hour_inspection_date: "",
  one_hundred_hour_inspection_image: "",
  operating_manuel: "",
  owner_id: "",
  price_perhour_usd: null,
  recurring_time: {
    days_of_week: "",
    price_perhour_usd: null,
    time: "",
  },
  registration_certificate: "",
  rules_guidelines: "",
  static_time: [],
  steam_gauges: true,
  transponder_date: "",
  transponder_image: "",
  vor_inspection_date: "",
  vor_inspection_image: "",
  weight_balance: "",
  year: "",
};
